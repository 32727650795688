<template>
    <div style="padding-top: 100px;">
        <div class="ps-contact">
            <div class="container">
                <div class="alert alert-info" v-if="isLoading">
                    Status yoxlanılır...
                </div>
                <div v-else>
                    <div class="alert alert-danger" v-if="isError">
                        Uğursuz ödəniş: {{paymentError}}
                    </div>
                    <div class="alert alert-success" v-else-if="isSuccess">
                        Uğurlu ödəniş.
                    </div>


                    <div class="my-3">

                        <a :href="`/profile/order/${orderId}`" class="ps-btn ps-btn--warning">
                            Sifariş səhifəsinə
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState} from "vuex";
    import {checkPayment} from "../../repositories/UserRepository";

    export default {
        name: "Payment",
        data() {
            return {
                isLoading: true,
                isError: false,
                paymentError: '',
                isSuccess: false
            }
        },
        computed: {
            ...mapState({
                settings: state => state.app.settings,
                user: state => state.auth.user,
                token: state => state.auth.token,
            }),
            orderId() {
                return this.$route.params.id;
            }
        },
        mounted() {


            checkPayment(this.token, this.orderId).then(data => {
                this.isLoading = false;
                if (data === null) {
                    this.isError = true;
                    this.paymentError = 'Zəhmət olmasa internet bağlantınızı yoxlayın'
                } else {
                    if (data.code === 200) {
                        this.isSuccess = true;
                        this.isError = false;
                    } else {
                        this.isSuccess = false;
                        this.isError = true;
                        this.paymentError = data.message + ": " + data.error;
                    }
                }
            })

        }
    }
</script>

<style scoped>

</style>