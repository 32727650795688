<template>
    <div class="ps-pagination">
        <ul class="pagination">
            <li><a href="#" @click.prevent="prevPage()"><i class="icon-chevron-left"></i></a></li>
            <li  v-if="startPage>1"> <a href="#" @click.prevent="pageIndex = 1">1</a></li>
            <li v-if="startPage>2"><a href="#"> ... </a></li>
            <li v-for="item in pageRange" :key="item" :class="[pageIndex === item ? 'active' : '']">
                <a href="#" @click.prevent="pageIndex = item">{{ item }}</a></li>
            <li v-if="length>endPage+1"><a href="#"> ... </a></li>
            <li  v-if="length>endPage"> <a href="#" @click.prevent="pageIndex = length">{{ length }}</a></li>
            <li><a href="#" @click.prevent="nextPage()"><i class="icon-chevron-right"></i></a></li>
        </ul>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        props: {
            page: {
                type: Number,
                default: 1
            },
            length: {
                type: Number,
                default: 1
            }
        },
        computed: {
            ...mapState({
                loading: state => state.app.loading
            }),
            pageIndex: {
                set(value) {
                    this.handleChangPage(value);
                    return value;
                },
                get() {
                    return this.page
                }
            },
            startPage() {
                if (this.pageIndex > 3) {
                    return this.pageIndex - 3;
                }
                return 1;
            },
            endPage() {
                if (this.pageIndex + 3 > this.length) {
                    return this.length;
                }
                return this.pageIndex + 3;
            },
            pageRange() {
                // 1 to 10
                const start = this.startPage;
                const end = this.endPage;
                const range = [...Array(end - start + 1).keys()].map(x => x + start);
                return range;
            }
        },
        methods: {
            handleChangPage(pageIndex) {
                if (pageIndex !== this.page) {
                    this.$emit('handleChangePage', pageIndex)
                }
            },
            prevPage() {
                if (this.pageIndex > 1) {
                    this.pageIndex--
                }
            },
            nextPage() {
                if (this.pageIndex < this.length) {
                    this.pageIndex++
                }
            }
        }
    }
</script>