<template>

    <modal-slot :isVisible="openDialogLanguage" title="Select language" @modalClose="handleCloseDialog(false)">
        <div class="ps-popup__body">
            <ul class="ps-dialog__list">
                <li class="language-item" v-for="item in dataList" :key="item.value">
                    <a :class="[ 'btn',  languageCode === item.code ? 'active' : '' ]" href="#"
                       @click.prevent="handleSelectLanguage(item)">
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </div>
    </modal-slot>

</template>

<script>
    import {mapState} from 'vuex';
    import ModalSlot from "./ModalSlot";

    export default {
        components: {ModalSlot},

        computed: {
            ...mapState({
                openDialogLanguage: state => state.app.openDialogLanguage,
                language: state => state.app.language,
                languageCode: state => state.app.languageCode,
                dataList: state => state.app.languages
            })
        },
        methods: {
            handleCloseDialog(value) {
                this.$store.commit('app/setDialogLanguage', value);
            },
            handleSelectLanguage(value) {
                this.$store.dispatch('app/setLanguage', value);
                this.handleCloseDialog(false);
            }
        },
        mounted() {
            console.log(this.dataList);
        }
    };
</script>
