<template>
    <modal-slot :isVisible="dialogNotify" title="" modalSize="md" @modalClose="handleCloseDialog(false)">

        <div class="ps-dialog__body">
            <div class="ps-dialog__content" v-if="messageNotify">
                <div class="text-center"  :class="{'text-danger': messageNotify.error}" v-if="messageNotify.icon" style="font-size: 50px">
                    <i :class="messageNotify.icon" ></i>
                </div>
                <p class="alert alert-info text-center" :class="{'alert-danger': messageNotify.error}">{{
                    messageNotify.message }}</p>
            </div>
        </div>
    </modal-slot>
</template>

<script>
    import {mapState} from 'vuex';
    import ModalSlot from "./ModalSlot";

    export default {
        components: {ModalSlot},
        computed: {
            ...mapState({
                dialogNotify: state => state.app.dialogNotify,
                messageNotify: state => state.app.messageNotify
            }),
            dialog: {
                set(value) {
                    this.handleCloseDialog(value);
                    return value;
                },
                get() {
                    return this.dialogNotify;
                }
            }
        },
        methods: {
            handleCloseDialog(value) {
                this.$store.commit('app/setDialogNotify', value);
            }
        }
    };
</script>
