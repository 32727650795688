import Repository, {baseUrl} from './Repository';

export async function getSettings() {
    const endPoint = `settings`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.settings;
            } else {
                return {};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {};
        });
}

export async function getLanguageList() {
    const endPoint = `languages`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.languages;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getPage(languageCode, slug) {
    const endPoint = `${languageCode}/page/${slug}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getCategories(languageCode) {
    const endPoint = `${languageCode}/categories`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.categories;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getBrands(languageCode) {
    const endPoint = `${languageCode}/brands`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.brands;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getBlocks(languageCode, place, isPromo) {
    const endPoint = `${languageCode}/blocks/${place}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                if(isPromo){
                    return  infoBlocksToPromos(response.data.blocks);
                }
                return response.data.blocks;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

function infoBlocksToPromos(infoBlocks) {

    let final = [];

    infoBlocks.forEach(function (infoBlock) {
        final.push(infoBlockToPromo(infoBlock));
    });

    return final;

}

function infoBlockToPromo(infoBlock, badge,) {
    return {
        banner: baseUrl + infoBlock.photo,
        badge: badge,
        title: infoBlock.title,
        btnName: infoBlock.text,
        link: infoBlock.link,
        classTitle: 'mb-20'
    };
}


export async function getBranches(languageCode) {
    const endPoint = `${languageCode}/branches`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.branches;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}
