<template>
    <div class="ps-category__wrapper">
        <div class="ps-category__type">
            <a v-for="(item, index) in shopType" :key="index" href="#" :class="{ active: item.name === viewType }"
               @click.prevent="handleChangeType(item.name)">
                <img :src="item.icon" alt/>
            </a>
        </div>
        <div class="ps-category__onsale" >
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="onSaleProduct" class="custom-control-input" v-model="onlyStock" @change="handleChangeStock">
                <label for="onSaleProduct" class="custom-control-label"> Stokda olanlar</label>
            </div>
        </div>
        <div class="ps-category__sort">
            <span>Sıralama: </span>
            <select v-model="sortType" @change="handleChangeSort">
                <option v-for="item in  sortItems" :value="item.value" :key="item.value">{{item.text}}</option>
            </select>
        </div>
        <div class="ps-category__show">
            <span>Səhifədə:</span>
            <select v-model.number="currentPageSize" @change="handleChangePageSize">
                <option v-for="item in  showItems" :value="item.value" :key="item.value">{{item.text}}</option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: ''
            },
            pageSize: {
                type: Number,
                default: 0
            },
            sort: {
                type: String,
                default: ''
            },
            filter: {
                type: Boolean,
                default: false
            },
            listType: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                sortType: "",
                currentPageSize: "",
                viewType: '',
                onlyStock: '',
                shopType: [
                    {
                        icon: '/img/icon/bars.svg',
                        name: 'list'
                    },
                    {
                        icon: '/img/icon/grid2.svg',
                        name: 'grid2'
                    },
                    {
                        icon: '/img/icon/grid3.svg',
                        name: 'grid3'
                    },
                    {
                        icon: '/img/icon/grid4.svg',
                        name: 'grid4'
                    }
                ],
                sortItems: [
                    {
                        text: 'Məsləhət görülən',
                        value: 'recommended'
                    },
                    {
                        text: 'Sonuncular',
                        value: 'latest'
                    },
                    {
                        text: 'Reytinq',
                        value: 'rating'
                    },
                    {
                        text: 'Ucuzdan bahaya',
                        value: 'price_asc'
                    },
                    {
                        text: 'Bahadan ucuza',
                        value: 'price_desc'
                    }
                ],
                showItems: [
                    {
                        text: '6',
                        value: 6
                    },
                    {
                        text: '12',
                        value: 12
                    },
                    {
                        text: '24',
                        value: 24
                    },
                    {
                        text: '36',
                        value: 36
                    },
                    {
                        text: '48',
                        value: 48
                    }
                ],
                openSeparate: false
            };
        },
        created() {
            this.sortType = this.sort;
            this.currentPageSize = this.pageSize;
            this.viewType = this.type;
            this.onlyStock = this.filter;
        },
        watch: {
            sort(val) {
                this.sortType = val;
            },
            pageSize(val) {
                this.currentPageSize = val;
            },
            filter(val) {
                this.onlyStock = val;
            }
        },
        methods: {
            handleChangeType(value) {
                this.viewType = value;
                this.$emit('handleChangeType', value);
            },
            handleChangePageSize() {
                this.$emit('handleChangePageSize', this.currentPageSize);
            },
            handleChangeSort() {
                this.$emit('handleChangeSort', this.sortType);
            },
            handleChangeStock() {
                console.log('handleChangeStock');
                console.log(this.onlyStock);
                this.$emit('handleChangeStock', this.onlyStock);
            }
        }
    };
</script>

<style scoped>
    select{
        border: none;
        background: #f0f2f5;
    }
</style>