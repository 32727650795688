<template>
    <section class="ps-section--featured">
        <div class="container">
            <h3 class="ps-section__title">{{ title }}</h3>
            <div class="ps-section__content">
                <div class="row" v-if="loading">
                    <div
                            class="col-6 col-sm-4 col-lg-2dot4"
                            v-for="item in 5"
                            :key="item"
                    >
                        Yüklənir...
                    </div>
                </div>
                <div class="row m-0" v-else>
                    <div
                            class="col-6 col-sm-4 col-lg-2dot4 p-0"
                            v-for="(item, index) in products"
                            :key="index"
                    >
                        <product-standard :product="item"/>
                    </div>
                </div>

                <div class="text-center">
                    <router-link class="ps-btn ps-btn--danger " to="/products">Bütün məhsullar</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ProductStandard from "../product/ProductStandard";
    import {mapState} from 'vuex';
    import {getTagProducts} from "../../repositories/ProductRepository";

    export default {
        props: {
            tag: {
                type: String,
                default: () => {
                }
            },
            title: {
                type: String,
                default: () => {
                }
            }
        },
        components: {ProductStandard},
        data() {
            return {
                dataList: []
            }
        },
        computed: {
            ...mapState({
                loading: state => state.app.loading,
                favorites: state => state.wishlist.items
            }),
            products(){
                let products = [];
                this.dataList.forEach((e) => {
                    e.isFavorite = this.favorites.indexOf(e.id + "") > -1;
                    products.push(e);
                })
                return products;
            }
        },
        async mounted() {
            await getTagProducts('az', this.tag).then(data => {
                this.dataList = data;
            });
        }
    };
</script>
