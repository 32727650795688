import {addToFavorites, getFavoriteIds, removeFromFavorites} from "../repositories/UserRepository";

const wishListStorage = {
    namespaced: true,
    state: () => ({
        items: [],
        count: 0
    }), mutations: {
        setItems(state, payload) {
            state.items = payload;
        },
        setCount(state, payload) {
            state.count = payload;
        },
    }, actions: {
        loadFavorites({commit, rootState}) {
            getFavoriteIds(rootState.auth.token).then((favorites) => {
                commit('setItems', favorites)
            });
        },
        addItemToWishlist({dispatch, rootState}, payload) {
            addToFavorites(rootState.auth.token, payload.id).then(() => {
                dispatch('loadFavorites')
            })
        },
        removeItemFromWishlist({dispatch, rootState}, payload) {
            removeFromFavorites(rootState.auth.token, payload.id).then(() => {
                dispatch('loadFavorites')
            })
        },

    }
};

export default wishListStorage;