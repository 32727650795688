<template lang="html">
    <div class="ps-navigation--footer">
        <div class="ps-nav__item">
            <a
                    href="javascript: void(0);"
                    @click="handleOpenDrawer('menu')"
                    v-if="!appDrawer || !currentDrawerContent || currentDrawerContent !== 'menu'"
            ><i class="icon-menu"></i
            ></a>
            <a
                    href="javascript: void(0);"
                    @click="handleCloseDrawer()"
                    v-if="appDrawer && currentDrawerContent && currentDrawerContent === 'menu'"
            ><i class="icon-cross"></i
            ></a>
        </div>
        <div class="ps-nav__item">
            <router-link to="/"><i class="icon-home2"></i></router-link>
        </div>
        <div class="ps-nav__item">
            <router-link to="/profile"><i class="icon-user"></i></router-link>
        </div>
        <div class="ps-nav__item">
            <router-link to="/profile/favorites">
                <i class="fa fa-heart-o"></i>
                <span class="badge">{{wishlistItems.length}}</span>
            </router-link>
        </div>
        <div class="ps-nav__item">
            <router-link to="/cart">
                <i class="icon-cart-empty"></i>
                <span class="badge">{{ productCartQuantity }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'NavigationBottom',
        computed: {
            ...mapState({
                appDrawer: state => state.app.appDrawer,
                currentDrawerContent: state => state.app.currentDrawerContent,
                cartItems: state => state.cart.cartItems,
                wishlistItems: state => state.wishlist.items
            }),
            productCartQuantity() {
                let quantity = 0;
                this.cartItems.forEach(element => {
                    quantity += element.count;
                });
                return quantity;
            }
        },
        data() {
            return {
                drawer: true
            };
        },
        methods: {
            handleOpenDrawer(drawer) {
                this.$store.commit('app/setCurrentDrawerContent', drawer);
                this.$store.commit('app/setAppDrawer', !this.appDrawer);
            },
            handleCloseDrawer() {
                this.$store.commit('app/setCurrentDrawerContent', null);
                this.$store.commit('app/setAppDrawer', false);
            }
        }
    };
</script>

<style lang="scss" scoped></style>
