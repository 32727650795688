<template>
    <div>

        <div class="ps-shopping">
            <h3 class="ps-shopping__title">Seçilmiş məhsullar </h3>

            <div class="my-4">

                <div class="ps-category--grid ps-category--detail">
                    <div class="row m-0">
                        <div
                                class="col-6 col-md-4 col-lg-3 p-0"
                                v-for="item in productList"
                                :key="item.id"
                        >
                            <product-standard :product="item"/>
                        </div>
                    </div>
                </div>

                <div class="text-center" v-if="productList.length === currentPage* pageSize">
                    <button type="button" class="ps-btn ps-btn--danger " @click="loadMore()">daha çox</button>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import {getFavorites} from "../../repositories/UserRepository";
    import ProductStandard from "../product/ProductStandard";

    export default {
        layout: 'default',
        components: {ProductStandard},
        data() {
            return {
                baseDomain: baseUrl,
                favorites: [],
                currentPage: 1,
                pageSize: 12
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                token: state => state.auth.token,
                favoriteIds: state => state.wishlist.items
            }),

            productList() {
                let result = [];
                this.favorites.forEach((p) => {
                    p.product.isFavorite = this.favoriteIds.indexOf(p.product.id + "") > -1;
                    result.push(p.product)
                });
                return result;
            },
        },
        async created() {

            this.getList(1);

            this.$store.commit('app/setLoading', false);
        },
        methods: {
            getList(page) {

                getFavorites(this.token, page, this.pageSize).then((favorites) => {
                    if (page === 1) {
                        this.favorites = favorites;
                    } else {
                        this.favorites = [
                            ...this.favorites,
                            ...favorites
                        ]
                    }
                })

            },

            loadMore() {
                this.currentPage++;
                this.getList(this.currentPage);
            }
        }
    }
</script>
