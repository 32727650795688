<template>
    <div>

        <div class="ps-shopping">
            <h3 class="ps-shopping__title">Son baxdığınız məhsullar </h3>

            <div class="my-4">

                <div class="ps-category--grid ps-category--detail">
                    <div class="row m-0">
                        <div
                                class="col-6 col-md-4 col-lg-3 p-0"
                                v-for="item in productList"
                                :key="item.id"
                        >
                            <product-standard :product="item"/>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import {getViewHistory} from "../../repositories/UserRepository";
    import ProductStandard from "../product/ProductStandard";

    export default {
        layout: 'default',
        components: {ProductStandard},
        data() {
            return {
                baseDomain: baseUrl,
                views: [],
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                token: state => state.auth.token,
                favoriteIds: state => state.wishlist.items
            }),

            productList() {
                let result = [];
                this.views.forEach((p) => {
                    p.product.isFavorite = this.favoriteIds.indexOf(p.product.id + "") > -1;
                    result.push(p.product)
                });
                return result;
            },
        },
        async created() {

            getViewHistory(this.token, 12).then((favorites) => {
                this.views = favorites;
            })

            this.$store.commit('app/setLoading', false);
        },
        methods: {}
    }
</script>
