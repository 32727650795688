<template>
    <div v-if="appDrawer" width="100%"
         style="position: absolute; top:0; left: 0; z-index: 1001; width: 100vw; height: 100vh !important; background: #ffffff">
        <navbar-slider v-if="currentDrawerContent === 'menu'"/>
        <search-slider v-else-if="currentDrawerContent === 'search'"/>
    </div>

</template>

<script>
    import {mapState} from 'vuex';
    import NavbarSlider from "./NavbarSlider";
    import SearchSlider from "./SearchSlider";


    export default {
        name: 'MobileDrawer',
        components: {NavbarSlider, SearchSlider},
        computed: {
            ...mapState({
                appDrawer: state => state.app.appDrawer,
                currentDrawerContent: state => state.app.currentDrawerContent
            }),
            drawer: {
                get() {
                    return this.appDrawer;
                },
                set(newValue) {
                    this.$store.commit('app/setAppDrawer', newValue);
                    return newValue;
                }
            }
        }
    };
</script>

<style lang="scss" scoped></style>
