<template>
    <div class="ps-header__search">
        <form @submit.prevent="searchSubmitted">
            <div class="ps-search-table">
                <div class="input-group">
                    <input
                            class="form-control ps-input"
                            type="text"
                            placeholder="Məhsul axtarışı"
                            v-model="textSearch"
                            @blur="openResult = false"
                            @input="handleSearch()"
                    />
                    <div class="input-group-append">
                        <a href="#" @click.prevent="handleSearch()"
                        ><i class="fa fa-search"></i
                        ></a>
                    </div>
                </div>
            </div>
        </form>
        <div :class="['ps-search--result', openResult ? 'active' : '']">
            <p v-if="!resultProduct" class="ps-result__empty">
                Axtarış nəticə vermədi!
            </p>
            <div class="ps-result__content" v-else>
                <div class="row m-0">
                    <template v-for="(product) in resultProduct" :key="product.id">
                        <div class="col-12 col-lg-6">
                            <product-horizontal :product="product"/>
                        </div>
                    </template>
                </div>

                <div class="ps-result__viewall" v-if="totalCount > 4">
                    <router-link :to="`/search?q=${textSearch}`">Bütün {{totalCount}} nəticəyə bax</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getAllProducts} from "../../repositories/ProductRepository";
    import ProductHorizontal from "../product/ProductHorizontal";

    export default {
        components: {ProductHorizontal},
        data() {
            return {
                textSearch: null,
                openResult: false,
                resultProduct: null,
                totalCount: 0
            };
        },
        methods: {
            async handleSearch() {
                if (this.textSearch != null && this.textSearch.length > 1) {
                    getAllProducts(this.languageCode, this.textSearch, 0, 1, 10, 'name').then(data => {
                            this.resultProduct = data.products.slice(0,4);
                            this.totalCount = data.total;
                        }
                    );
                    this.openResult = true;
                } else {
                    this.resultProduct = [];
                    this.openResult = false;
                    this.totalCount= 0;
                }
            },

            searchSubmitted() {
                if (this.textSearch != null && this.textSearch.length > 2) {
                    this.$router.push(`/search?q=${this.textSearch}`);
                    this.resultProduct = [];
                    this.openResult = false;
                }
            }
        }
    };
</script>
