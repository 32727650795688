<template>
    <div class="ps-cart--mini">
        <div class="ps-cart__empty" v-if="!cartItems.length">
            <div class="ps-cart__icon">
                <i class="fa fa-shopping-basket"></i>
            </div>
            <p class="ps-cart__text">Səbətiniz boşdur</p>
        </div>
        <template v-else>
            <ul class="ps-cart__items">
                <li
                        class="ps-cart__item"
                        v-for="(cartItem, index) in cartItems"
                        :key="index"
                >
                    <product-mini-cart :product="cartItem.product"/>
                </li>
            </ul>
            <div class="ps-cart__total">
                <span>Cəmi </span><span>{{totalCart}} AZN</span>
            </div>
            <div class="ps-cart__footer">
                <a class="ps-btn ps-btn--outline" href="/cart">Səbətə keç</a>
            </div>
        </template>
    </div>
</template>

<script>
    import ProductMiniCart from "../product/ProductMiniCart";
    import {mapState} from 'vuex';

    export default {
        components: {ProductMiniCart},
        computed: {
            ...mapState({
                cartItems: state => state.cart.cartItems
            }),
            totalCart() {
                let total = 0;
                this.cartItems.forEach(element => {
                    let price = this.productPrice(element.product);
                    total += price * element.count;
                });
                return total.toFixed(2);
            }
        },
        methods: {
            productPrice(product) {
                if (product) {
                    return product.sale_price ? product.sale_price : product.price;
                } else {
                    return 0;
                }
            }
        }
    };
</script>
