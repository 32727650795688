<template>
    <div class="ps-menu--slidebar">
        <div class="ps-menu__content">
            <module-main-menu :list="mainMenu" className="menu--mobile"/>
        </div>
        <div class="ps-menu__footer">
            <div class="ps-menu__item">
                <module-language-currency/>
            </div>
            <!--<div class="ps-menu__item">
                <div class="ps-menu__contact">Need help? <strong>0020 500 - MYMEDI - 000</strong></div>
            </div>-->
        </div>
    </div>
</template>

<script>
    import ModuleMainMenu from "./mobile/ModuleMainMenu";
    import ModuleLanguageCurrency from "./mobile/ModuleLanguageCurrency";
    import {getCategories} from "../../repositories/GeneralDataRepository";

    export default {
        components: {ModuleMainMenu, ModuleLanguageCurrency},
        data() {
            return {
                categories: []
            };
        },
        computed: {
            mainMenu() {
                return [
                    {
                        "text": "Məhsullar",
                        "url": "/products",
                        "extraClass": "menu-item-has-children",
                        "subClass": "sub-menu",
                        "subMenu": this.categories
                    },
                    {
                        "icon": "fa fa-building",
                        "text": "Haqqımızda",
                        "url": "/page/about",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icon": "fa fa-lock",
                        "text": "Məxfilik",
                        "url": "/page/privacy",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icon": "fa fa-certificate",
                        "text": "Sertifikatlar",
                        "url": "/page/certificates",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icon": "fa fa-copyright",
                        "text": "Brendlər",
                        "url": "/brands",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icon": "fa fa-book",
                        "text": "Kataloqlar",
                        "url": "/catalogs",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-book",
                        "text": "Qalereya",
                        "url": "/gallery",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icon": "fa fa-envelope",
                        "text": "Əlaqə",
                        "url": "/contact",
                        "extraClass": "nav-item mr-2"
                    }
                ]
            },
        },
        async mounted() {
            await getCategories(this.languageCode).then(data => {

                const categories = [];

                data.forEach(cat => {
                    categories.push({
                        text: cat.name,
                        url: `/category/${cat.slug}`
                    })
                })

                this.categories = categories;
            });
        }
    };
</script>
