<template>
    <ul class="ps-language-currency" v-if="languages.length>1">
        <li class="menu-item-has-children">
            <a @click.prevent="openSubItem()">{{data.text}}</a>
            <span class="sub-toggle" @click.prevent="openSubItem()"><i class="fa fa-chevron-down"></i></span>
            <slide-up-down v-model="isOpen">
                <ul :class="data.subClass">
                    <li v-for="(val, indexVal) in data.subMenu" :key="indexVal">
                        <a href="#">{{val.text}}</a>
                    </li>
                </ul>
            </slide-up-down>
        </li>
    </ul>
</template>

<script>
    import SlideUpDown from 'vue3-slide-up-down'
    import {mapState} from "vuex";

    export default {
        components: {SlideUpDown},
        data() {
            return {
                isOpen: false
            }
        },
        computed: {
            ...mapState(
                {
                    languages: state => state.app.languages,
                    language: state => state.app.language,
                }
            ),
            data() {
                console.log(this.language);
                console.log(this.languages);
                return {
                    text: this.language,
                    subClass: "sub-menu",
                    url: '#',
                    subMenu: this.languages
                };
            }
        }
        ,
        methods: {
            openSubItem() {
                this.isOpen = !this.isOpen;
            }
        }
    }
</script>
