<template>

    <modal-slot :isVisible="dialogRate" title="Məhsul dəyərləndirilməsi" modalSize="lg"
                @modalClose="closeModel(false)">

        <div class="row">
            <div class="col-12 col-md-5">
                <product-standard :product="productOverview"/>
            </div>
            <div class="col-12 col-md-7">

                <form @submit.prevent >

                    <div class="ps-form__group mb-4">
                        <star-rating v-model:rating="rating"  :increment="1" :show-rating="false" :active-on-click="true"/>
                    </div>
                    <div class="ps-form__group">
                        <label for="registerName" class="ps-form__label">Qeydləriniz</label>
                        <textarea
                                class="form-control ps-form__input p-3"
                                type="text"
                                id="registerName"
                                v-model="reviewText"
                                placeholder="Məhsulla bağlı fikirlərinizi qeyd edin"
                        ></textarea>
                    </div>

                    <div class="ps-form__submit mt-3">
                        <button class="ps-btn ps-btn--warning mr-4" type="submit" @click.prevent="rateProduct">
                            Dəyərləndir
                        </button>
                    </div>

                </form>

            </div>
        </div>
    </modal-slot>
</template>

<script>
    import {mapState} from 'vuex';
    import ModalSlot from "../layout/ModalSlot";
    import ProductStandard from "./ProductStandard";
    import { rateProduct} from "../../repositories/UserRepository";

    import StarRating from 'vue-star-rating'

    export default {
        components: {ModalSlot, ProductStandard, StarRating},
        data() {
            return {
                reviewText: '',
                rating: 0
            }
        },
        computed: {
            ...mapState({
                token: state => state.auth.token,
                languageCode: state => state.app.languageCode,
                dialogRate: state => state.cart.dialogRating,
                productOverview: state => state.cart.productOverview
            }),
            dialog: {
                set(value) {
                    this.closeModel(value);
                    return value;
                },
                get() {
                    return this.dialogRate;
                }
            }
        },
        methods: {
            closeModel(value) {
                this.$store.commit('cart/setDialogRating', value);
            },
            rateProduct(){
                if(this.rating<1){
                    const message = {
                        icon: 'icon-cross',
                        title: "Diqqət",
                        message: 'Zəhmət olmasa qiyməti seçin!',
                        error: true
                    };
                    this.$store.commit('app/setMessageNotify', message);
                    this.$store.commit('app/setDialogNotify', true);
                } else {
                    rateProduct(this.token, this.productOverview.id, this.rating, this.reviewText).then((res) => {
                        if (res) {
                            this.reviewText = '';
                            this.rating = 0;
                            const message = {
                                icon: 'icon-shield-check',
                                title: "Uğurlu əməliyyat",
                                message: 'Sorğunuz qeydə alındı!',
                                error: false
                            };
                            this.$store.commit('app/setMessageNotify', message);
                            this.$store.commit('app/setDialogNotify', true);
                            this.$store.commit('cart/setDialogRating', false);
                        } else {
                            const message = {
                                icon: 'fa fa-exclamation-triangle',
                                title: "Uğursuz əməliyyat",
                                message: 'Sorğunuz qeydə alınmadı!',
                                error: true
                            };
                            this.$store.commit('app/setMessageNotify', message);
                            this.$store.commit('app/setDialogNotify', true);
                        }
                    })
                }
            }
        },
        watch: {}
    };
</script>

