<template>
    <div class="ps-product--gallery">
        <div class="ps-swiper">
            <swiper class="swiper ps-product__thumbnail"
                    :slides-per-view="1"
                    :space-between="0"
                    :navigation="true"
                    :zoom="true"
                    :thumbs="{swiper:thumbsSliderController}"
            >
                <swiper-slide v-for="(item, index) in images" :key="index">

                    <div class="swiper-zoom-container">
                        <img :src="`${imagePathLarge}${item.url}`" style="width: 100%" alt/>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="ps-swiper">
            <swiper class="swiper ps-gallery--image"
                    :slides-per-view="5"
                    :space-between="10"
                    :freeMode="true"
                    :centeredSlides="true"
                    @swiper="onThumbsSwiper"
                    watch-slides-visibility
                    watch-slides-progress
            >
                <swiper-slide v-for="(item, index) in images" :key="index">
                    <div class="ps-gallery__item">
                        <img :src="`${imagePath}${item.url}`" :alt="item.alt"/>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl, imagePath, imagePathLarge} from "../../repositories/Repository";

    import SwiperCore, {Thumbs, Navigation, Zoom} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';


    import 'swiper/swiper.scss';
    import 'swiper/components/navigation/navigation.scss';
    import "swiper/components/thumbs/thumbs.min.css"
    import "swiper/components/zoom/zoom.min.css"

    SwiperCore.use([Thumbs, Navigation, Zoom]);

    export default {
        components: {Swiper, SwiperSlide},
        props: {
            images: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                baseDomain: baseUrl,
                imagePath: imagePath,
                imagePathLarge: imagePathLarge,
                style: false,
                imageSliderController: null,
                thumbsSliderController: null
            }
        },
        computed: {
            ...mapState({
                loading: state => state.app.loading
            })
        },
        methods: {
            onThumbsSwiper(swiper) {
                this.thumbsSliderController = swiper;
            }
        },
        mounted() {
        }
    };
</script>

<style lang="scss">
    .swiper-slide-thumb-active {
        .ps-gallery__item {
            border-color: #103178 !important;
        }
    }
</style>
