<template>
    <header :class="['ps-header ps-header--2 ps-header--mobile', {'ps-header--sticky': sticky}]">
        <div class="ps-header__middle">
            <div class="container">
                <div class="ps-logo">
                    <a href="/"> <img :src="logo" alt /></a>
                </div>
                <div class="ps-header__right">

                    <ul class="ps-header__icons">
                        <li><label><input type="checkbox" name="taxIncluded" value="1" v-model.number="isTaxIncluded">
                            ədv daxil</label>
                        </li>
                    </ul>

                </div>
                <FormSearch />
            </div>
        </div>
    </header>
</template>

<script>
    import {mapState} from 'vuex';
    import logo from "../../assets/img/logo.png";
    import FormSearch from "./FormSearch";

    export default {
        components: {FormSearch},
        data() {
            return {
                logo: logo,
                sticky: false
            }
        },
        computed: {
            ...mapState({
                appDrawer: state => state.app.appDrawer,
                taxIncluded: state => state.app.taxIncluded
            }),
            isTaxIncluded: {
                get() {
                    return this.taxIncluded ? 1 : 0;
                },
                set(v) {
                    this.$store.commit('app/setTaxIncluded', v > 0);
                    localStorage.setItem('taxIncluded', v);
                }
            },
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
            this.handleScroll();
        },
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                let scroll = window.scrollY;
                if (scroll > 700) {
                    this.sticky = true;
                } else {
                    this.sticky = false;
                }
            },
            handleOpenDrawer(drawer) {
                this.$store.commit('app/setCurrentDrawerContent', drawer);
                this.$store.commit('app/setAppDrawer', !this.appDrawer);
            }
        }
    };
</script>
