<template>
    <div style="padding-top: 50px;">
        <div class="ps-about"  >
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
            </div>
            <div class="ps-about__content">
                <div class="container">
                    <h1 class="ps-blog__title">Qalereya</h1>
                </div>
                <section class="ps-about__project">
                    <div class="container">


                        <div class="row">
                        <div class="col-6 col-md-4 col-lg-3 mb-3" v-for="item in dataList" :key="item.id">
                            <a :href="baseUrl  + item.photo" target="_blank" >
                                <img :src="baseUrl+'/photo.php?w=600&h=400&zc=1&src=' + item.photo" :alt="item.title">
                            </a>
                        </div>
                    </div>

                    </div>
                </section>


            </div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import {getBlocks} from "../../repositories/GeneralDataRepository";
    import {mapState} from "vuex";
    import {baseUrl, imagePath} from "../../repositories/Repository";

    export default {
        name: "Gallery",
        components: {  Breadcrumb},
        data() {

            return {
                baseUrl: baseUrl,
                imagePath: imagePath,
                isLoading: true,
                notFound: false,
                breadcrumb: [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/',
                        extraClass: 'active',
                        text: 'Qalereya'
                    }
                ],
                dataList: []
            };

        },
        computed: {
            ...mapState({
                languageCode: state => state.app.languageCode
            })
        },
        watch: {
            '$route'() {
                this.loadData();
            }
        },
        methods: {
            async loadData() {

                this.isLoading = true;
                this.$store.commit('app/setLoading', true);
                await getBlocks(this.languageCode, 'gallery', false).then(data => {
                    this.dataList = data;
                })

                this.$store.commit('app/setLoading', false);

            }
        },

        async mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>