<template>


    <div class="ps-category">
        <div class="container">
            <breadcrumb :dataList="breadcrumb"/>

            <h1 class="ps-category__name">Axtarış: "{{$route.query['q']}}"<sup
                    v-if="totalProductSize>0">({{totalProductSize}})</sup>
            </h1>

            <div class="ps-category__content">
                <div class="row ">
                    <div class="col-12 ">


                        <shop-wrapper
                                :type="type"
                                :pageSize="pageSize"
                                :sort="sortBy"
                                @handleChangeType="handleChangeType"
                                @handleChangeSort="handleChangeSort"
                                @handleChangePageSize="handleChangePageSize"
                        />

                        <div v-if="productList.length<1">
                            <div class="alert alert-info my-5 lead">
                                Axtarışınıza uyğun nəticə tapılmadı
                            </div>
                        </div>

                        <div v-if="type === 'list'" class="ps-category--list">
                            <template v-if="loading">
                                <div class="ps-product ps-product--list" v-for="item in 3" :key="item">
                                    <!--<div class="ps-product__content">
                                        <div class="ps-product__thumbnail">
                                            <v-skeleton-loader type="image" ></v-skeleton-loader>
                                        </div>
                                        <div class="ps-product__info">
                                            <v-skeleton-loader type="article" ></v-skeleton-loader>
                                        </div>
                                    </div>
                                    <div class="ps-product__footer">
                                        <v-skeleton-loader type="article" ></v-skeleton-loader>
                                    </div>-->
                                </div>
                            </template>
                            <template v-else>
                                <product-listing v-for="item in productList" :key="item.id" :product="item"/>
                            </template>
                        </div>
                        <div v-else-if="type === 'grid2'" class="ps-category--grid ps-category--detail">
                            <div class="row" v-if="loading">
                                <div class="col-6 col-md-4" v-for="item in 3" :key="item">
                                    <!--<v-skeleton-loader type="image, article" ></v-skeleton-loader>-->
                                </div>
                            </div>
                            <div class="row m-0" v-else>
                                <div
                                        class="col-6 col-md-4 p-0"
                                        v-for="item in productList"
                                        :key="item.id"
                                >
                                    <product-standard :product="item"/>
                                </div>
                            </div>
                        </div>
                        <div class="ps-category--grid" v-else-if="type === 'grid3'">
                            <div class="row" v-if="loading">
                                <div class="col-6 col-md-4 col-lg-3" v-for="item in 4" :key="item">
                                    <!--<v-skeleton-loader type="image, article" ></v-skeleton-loader>-->
                                </div>
                            </div>
                            <div class="row m-0" v-else>
                                <div
                                        class="col-6 col-md-4 col-lg-3 p-0"
                                        v-for="item in productList"
                                        :key="item.id"
                                >
                                    <product-standard :product="item"/>
                                </div>
                            </div>
                        </div>
                        <div v-else class="ps-category--separate">
                            <div class="row" v-if="loading">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="item in 4" :key="item">
                                    <!--<v-skeleton-loader  type="image, article" ></v-skeleton-loader>-->
                                </div>
                            </div>
                            <div class="row m-0" v-else>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-0" v-for="item in productList"
                                     :key="item.id">
                                    <product-standard :product="item"/>
                                </div>
                            </div>
                        </div>
                        <pagination v-if="pageLength>0"
                                    :page="pageIndex"
                                    :length="pageLength"
                                    @handleChangePage="handleChangePage"
                        />
                        <!--<delivery />-->


                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import ProductStandard from "../product/ProductStandard";
    import ShopWrapper from "../layout/ShopWrapper";
    import ProductListing from "../product/ProductListing";
    import Pagination from "../layout/Pagination";
    import {mapState} from "vuex";
    import {getAllProducts} from "../../repositories/ProductRepository";
    import {baseUrl} from "../../repositories/Repository";

    export default {
        name: "Products",
        components: {ProductStandard, ShopWrapper, Breadcrumb, ProductListing, Pagination},
        props: {
            pageType: {
                type: String,
                default: () => {
                }
            },
            categorySlug: {
                type: String,
                default: () => {
                }
            },
        },
        data() {
            return {
                mediaBase: baseUrl,
                type: 'grid3',
                products: [],
                totalProductSize: 0,
                pageSize: 24,
                pageIndex: 1,
                sortBy: 'recommended',
                categories: [],
                activeCategory: 1,
                pageTitle: "",
                pageBanner: "",
                pageDescription: "",
                pageNotFound: false
            };
        },
        watch: {
            '$route'() {
                this.getProductList();
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            pageSize(newVal, oldVal) {
                console.log(oldVal + ' -> ' + newVal);
            }
        },
        computed: {
            ...mapState({
                languageCode: state => state.app.languageCode,
                loading: state => state.app.loading,
                favorites: state => state.wishlist.items
            }),
            slug() {
                return this.$route.params.slug;
            },
            pageLength() {
                return (this.totalProductSize > 0 ? Math.ceil(this.totalProductSize / this.pageSize) : 0);
            },
            productList() {
                let result = [];
                this.products.forEach((p) => {
                    p.isFavorite = this.favorites.indexOf(p.id + "") > -1;
                    result.push(p)
                });
                return result;
            },
            breadcrumb() {
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/products',
                        text: 'Məhsullar'
                    }
                ];
            },
        },
        mounted() {
            this.$store.commit('app/setLoading', true);

            if (this.$route.query.page) {
                this.pageIndex = this.$route.query.page;
            }
            if (this.$route.query.size) {
                this.pageSize = this.$route.query.size;
            }
            if (this.$route.query.sort) {
                this.sortBy = this.$route.query.sort;
            }
            if (this.$route.query.view) {
                this.type = this.$route.query.view;
            }

            this.getProductList();
        },
        methods: {
            async getProductList() {


                //this.$router.push(`/products?page=${this.pageIndex}&size=${this.pageSize}&sort=${this.sortBy}&view=${this.type}`);

                await getAllProducts(this.languageCode, this.$route.query['q'], 0, this.pageIndex, this.pageSize, this.sortBy).then(data => {
                        this.products = data.products;
                        this.totalProductSize = data.total
                    }
                );
                this.$store.commit('app/setLoading', false);


            },
            handleChangePage(val) {
                this.pageIndex = val;
                this.getProductList();
                window.scrollTo({
                    top: 0, left: 0,
                    behavior: 'smooth'
                });
            },
            handleChangeType(val) {
                this.type = val;
            },
            handleChangeSort(val) {
                this.sortBy = val;
                this.pageIndex = 1;
                this.getProductList();
            },
            handleChangePageSize(val) {
                this.pageSize = val;
                this.pageIndex = 1;
                this.getProductList();
            },
            handleFilter(type, value) {
                // list type: price, color, branch, rating
                console.log(type, value);
            }
        }
    }
</script>

<style scoped>

</style>