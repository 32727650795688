<template>
    <div v-if="pageNotFound">
        <NotFoundPage></NotFoundPage>
    </div>
    <div v-else  >
        <div class="ps-page--product4">
            <div class="container" v-if="productLoaded">
                <breadcrumb :dataList="breadcrumb"/>
                <div class="ps-page__content">
                    <div class="ps-product--detail ps-product--full">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-5">
                                <module-product-gallery v-if="productDetail.hasOwnProperty('images')"
                                                        :images="productDetail.images"/>

                                <div v-if="productDetail.video !== ''">
                                    <video class="w-100" controls>
                                        <source :src="`${baseDomain}${productDetail.video}`">
                                    </video>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-7">
                                <product-info :product="productDetail" :design="design"/>
                            </div>
                        </div>
                    </div>
                </div>


                <featured-product title="Ən çox satılan məhsullar" tag="top"/>

            </div>
        </div>
    </div>
</template>

<script>

    import {baseUrl} from "../../repositories/Repository";
    import {formatProduct, getProduct} from "../../repositories/ProductRepository";
    import {mapState} from "vuex";

    import Breadcrumb from "../layout/page/Breadcrumb";
    import ProductInfo from "../product/ProductInfo";
    import ModuleProductGallery from "../product/ModuleProductGallery";
    import FeaturedProduct from "../layout/FeaturedProduct";
    import NotFoundPage from "./NotFoundPage";
    import {addToViewHistory} from "../../repositories/UserRepository";

    export default {
        components: {Breadcrumb, ProductInfo, ModuleProductGallery, FeaturedProduct,NotFoundPage},
        data() {
            return {
                baseDomain: baseUrl,
                productLoaded: false,
                productDetail: {},
                design: {
                    social: false,
                    extent: false,
                    branch: 'HeartRate',
                    inStock: true,
                    type: false
                },

                pageNotFound: false
            }
        },
        computed: {
            ...mapState({
                languageCode: state => state.app.languageCode,
                loading: state => state.app.loading,
                token: state=> state.auth.token,
                hash: state=> state.auth.hash,
            }),
            slug() {
                return this.$route.params.slug;
            },
            breadcrumb() {

                console.log(this.productDetail);
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/products',
                        text: 'Məhsullar'
                    },
                    {
                        url: '/category/' + this.productDetail.category.slug,
                        text: this.productDetail.category.name
                    },
                    {
                        url: '/product/' + this.productDetail.slug,
                        extraClass: 'active',
                        text: this.productDetail.name
                    }
                ];
            },
        },

        methods: {
            async getCurrentProduct() {
                this.$store.commit('app/setLoading', true);
                await getProduct(this.languageCode, this.slug).then(data => {
                    if (data.length < 1) {
                        this.pageNotFound = true;
                    } else {
                        this.pageNotFound = false;
                        this.pageTitle = data.product.name;
                        this.productDetail = formatProduct(data.product, data.gallery);
                        this.productLoaded = true;
                    }
                })

                this.$store.commit('app/setLoading', false);

            }
        },
        watch: {
            '$route'() {
                this.getCurrentProduct();
                console.log(this.$route);
                window.scrollTo(0, 0);
            },
            productDetail(newVal){
                if(newVal != null){
                    addToViewHistory(this.token, this.hash, newVal.id);
                }
            }
        },

        created() {
            this.getCurrentProduct();
        }

    }

</script>

<style scoped>

</style>