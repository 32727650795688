<template>
    <div class="ps-search">
        <div class="ps-search__content ps-search--mobile">
            <a
                    class="ps-search__close"
                    href="#"
                    @click.prevent="handleCloseDrawer()"
            ><i class="icon-cross"></i></a>
            <h3>Axtarış</h3>
            <form @submit.prevent="searchSubmitted">
                <div class="ps-search-table">
                    <div class="input-group">
                        <input
                                class="form-control ps-input"
                                type="text"
                                placeholder="Məhsul axtarışı"
                                v-model="textSearch"
                                @input="handleSearch()"
                        />
                        <div class="input-group-append">
                            <a href="#" @click.prevent="handleSearch()"
                            ><i class="fa fa-search"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </form>
            <div :class="['ps-search__result', openResult ? 'active' : '']">
                <p v-if="resultProduct.length<1" class="ps-result__empty">
                    Məhsul tapılmadı!
                </p>
                <div v-else>
                    <div class="row">
                        <div class="col-md-6"
                             v-for="product in resultProduct"
                             :key="product.id">
                            <div
                                    class="ps-search__item"
                            >
                                <product-horizontal :product="product"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ProductHorizontal from "../product/ProductHorizontal";
    import {getAllProducts} from "../../repositories/ProductRepository";
    // import { getProductSearch } from '~/repositories/ProductRepository';

    export default {
        components: {
            ProductHorizontal
        },
        data() {
            return {
                textSearch: null,
                openResult: false,
                resultProduct: []
            };
        },
        computed: {
            ...mapState({
                appDrawer: state => state.app.appDrawer,
                languageCode: state => state.app.languageCode,
            })
        },
        methods: {
            handleCloseDrawer() {
                this.openResult = false;
                this.$store.commit('app/setCurrentDrawerContent', null);
                this.$store.commit('app/setAppDrawer', false);
            },
            async handleSearch() {
                if(this.textSearch != null && this.textSearch.length >1) {
                    getAllProducts(this.languageCode, this.textSearch, 0, 1, 10, 'name').then(data => {
                            this.resultProduct = data.products;
                        }
                    );
                    this.openResult = true;
                } else {
                    this.resultProduct = [];
                    this.openResult = false;
                }
            },
            searchSubmitted() {
                if (this.textSearch != null && this.textSearch.length > 2) {
                    this.$router.push(`/search?q=${this.textSearch}`);
                    this.resultProduct = [];
                    this.openResult = false;
                }
            }
        }
    };
</script>
