<template>

    <modal-slot  :isVisible="dialogQuickview" title="" modalSize="xl"
                @modalClose="closeModel(false)">

        <div class="ps-quickview__body">
            <div class="ps-product--detail" v-if="productOverview">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <module-product-gallery :images="productOverview.images"/>
                    </div>
                    <div class="col-12 col-md-6">
                        <product-info :product="productOverview" :design="design"/>
                    </div>
                </div>
            </div>
        </div>
    </modal-slot>
</template>

<script>
    import {mapState} from 'vuex';
    import ProductInfo from "./ProductInfo";
    import ModuleProductGallery from "./ModuleProductGallery";
    import ModalSlot from "../layout/ModalSlot";

    export default {
        components: {ModalSlot, ProductInfo, ModuleProductGallery},
        data() {
            return {
                design: {
                    inStock: true,
                    branch: 'HeartRate',
                    quickview: true,
                    type: true
                }
            }
        },
        computed: {
            ...mapState({

                languageCode: state => state.app.languageCode,
                dialogQuickview: state => state.cart.dialogQuickview,
                productOverview: state => state.cart.productOverview
            }),
            dialog: {
                set(value) {
                    this.closeModel(value);
                    return value;
                },
                get() {
                    return this.dialogQuickview;
                }
            }
        },
        methods: {
            closeModel(value) {
                this.$store.commit('cart/setDialogQuickview', value);
            }
        },
        watch: {
            /*productOverview() {
                if(this.productOverview!= null) {
                    getProduct(this.languageCode, this.productOverview.slug).then((product => {
                            this.$store.commit('cart/setProductOverview', product);
                        }
                    ));
                }
            }*/
        }
    };
</script>

