

const homeStorage = {
    state() {

    },
    mutations: {},
    actions: {},
    getters: {}
};

export default homeStorage;