<template>
    <div style="padding-top: 100px;">
        <div class="ps-shopping">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
                <h3 class="ps-shopping__title">Səbət<sup>({{ productCartQuantity }})</sup></h3>
                <div class="ps-shopping__content">
                    <div class="row">
                        <div class="col-12 col-sm-7 col-md-9">
                            <ul class="ps-shopping__list">
                                <li v-for="(chartItem, index) in cartItems" :key="index">
                                    <product-wishlist :product="chartItem.product" :quantity="chartItem.count"
                                                      type="cart"/>
                                </li>
                            </ul>
                            <div class="ps-shopping__table">

                                <table class="table ps-table ps-table--product">
                                    <thead>
                                    <tr>
                                        <th class="ps-product__remove"></th>
                                        <th class="ps-product__thumbnail"></th>
                                        <th class="ps-product__name">Məhsul</th>
                                        <th class="ps-product__meta">Qiymət</th>
                                        <th class="ps-product__quantity">Miqdar</th>
                                        <th class="ps-product__subtotal">Cəmi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(cartItem, index) in cartItems" :key="index">
                                        <td class="ps-product__remove">
                                            <a href="#" @click.prevent="handleRemove(cartItem.product)"><i
                                                    class="icon-cross"></i></a></td>
                                        <td class="ps-product__thumbnail">
                                            <router-link class="ps-product__image"
                                                         :to="`/product/${cartItem.product.slug}`"
                                                         v-if="cartItem.product.thumbnail">
                                                <figure><img :src="baseDomain + cartItem.product.thumbnail.url" alt>
                                                </figure>
                                            </router-link>
                                        </td>
                                        <td class="ps-product__name">
                                            <router-link :to="`/product/${cartItem.product.slug}`">
                                                {{cartItem.product.name }}
                                            </router-link>
                                        </td>
                                        <td class="ps-product__meta">
                                            <div v-if="cartItem.product.is_stock">
                                                <span :class="[
                                                    'ps-product__price',
                                                    cartItem.product.sale_price ? 'sale' : ''
                                                ]" >
                                                    {{
                                                    cartItem.product.sale_price ? cartItem.product.sale_price : productPrice(cartItem.product.price, taxIncluded)
                                                }} AZN
                                                </span>
                                                <span class="ps-product__del" v-if="cartItem.product.sale_price">
                                                    {{ productPrice(cartItem.product.price, taxIncluded) }} AZN
                                                </span>
                                            </div>
                                            <div v-else>
                                                <span class="badge badge-danger">
                                                tükənmişdir
                                            </span>
                                            </div>

                                        </td>
                                        <td class="ps-product__quantity" v-if="cartItem.product.is_stock">
                                            <div class="def-number-input number-input safari_only">
                                                <button
                                                        class="minus"
                                                        @click="handleDecreaseItem(cartItem.product, cartItem.count)"
                                                >
                                                    <i class="icon-minus"></i>
                                                </button>
                                                <input style="border: none"
                                                       class="quantity"
                                                       min="0"
                                                       :value="cartItem.count"
                                                       type="number"
                                                       @input="val => handleChangeQuantity(val, cartItem.product)"
                                                />
                                                <button class="plus"
                                                        @click="handleIncreaseItem(cartItem.product, cartItem.count)">
                                                    <i class="icon-plus"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <td class="ps-product__subtotal" v-if="cartItem.product.is_stock">
                                            {{ productTotal(cartItem.product, cartItem.count) }} AZN
                                        </td>
                                    </tr>
                                    <tr v-if="!cartItems || !cartItems.length">
                                        <td colspan="6">Səbətiniz boşdur</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="ps-shopping__footer">
                                <div class="ps-shopping__button mb-3">
                                    <button v-if="cartItems.length>0" class="ps-btn ps-btn--primary" type="button"
                                            @click="handleClearAll()">
                                        Səbəti təmizlə
                                    </button>
                                    <button class="ps-btn ps-btn--primary" type="button" @click="handleUpdateCart()">
                                        Səbəti yenilə
                                    </button>
                                </div>

                                <div class="ml-4 py-3 px-5 rounded-pill bg-danger text-white  mb-3 ">
                                    <strong>300 &#8380;</strong>-dən yuxarı olan sifarişlər Bakı şəhəri üçün <strong>ÖDƏNİŞSİZ</strong> çatdırılır.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-5 col-md-3" v-if="cartItems.length>0">
                            <div class="ps-shopping__box">

                                <div class="ps-shopping__row">
                                    <div class="ps-shopping__label">Cəmi</div>
                                    <div class="ps-shopping__price">{{ totalCart }} AZN</div>
                                </div>
                                <div class="ps-shopping__checkout">
                                    <div v-if="taxIncluded">
                                        <a href="#" class="ps-btn ps-btn--warning" @click.prevent="createOrder()">
                                            Sifariş ver
                                        </a>
                                    </div>
                                    <div v-else>
                                        <div class="alert alert-warning">
                                            Online rejimdə sadəcə ƏDV daxil qiymətlərlə sifariş etmək mümkündür
                                        </div>
                                    </div>
                                    <router-link class="ps-shopping__link" to="/products">
                                        Alışverişə davam et
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from "../layout/page/Breadcrumb";
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import ProductWishlist from "../product/ProductWishlist";
    import {createOrder} from "../../repositories/UserRepository";
    // import ProductWishlist from '~/components/elements/product/ProductWishlist';

    export default {
        layout: 'default',
        components: {ProductWishlist, Breadcrumb},
        data() {
            return {
                breadcrumb: [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/cart',
                        extraClass: 'active',
                        text: 'Səbət'
                    }
                ],
                baseDomain: baseUrl,
                calculateShipping: false,

                region: null,
                country: null,
                town: null,
                postcode: null,
                couponCode: null
            }
        },
        computed: {
            ...mapState({
                cartItems: state => state.cart.cartItems,
                user: state => state.auth.user,
                token: state => state.auth.token,

                taxIncluded: state => state.app.taxIncluded
            }),
            totalCart() {
                let total = 0;
                this.cartItems.forEach(element => {
                    console.log("chart");
                    console.log(element);
                    let price = this.productPrice(element.product.price, this.taxIncluded);
                    total += price * element.count;
                });
                return total.toFixed(2);
            },
            productCartQuantity() {
                let quantity = 0;
                this.cartItems.forEach(element => {
                    quantity += element.count;
                });
                return quantity;
            }
        },
        async mounted() {
            this.$store.commit('app/setLoading', false);
        },
        methods: {
            handleCoupon() {
                console.log('couponCode', this.couponCode);
            },
            handleUpdateCart() {

                this.$store.commit('app/setLoading', true);
                this.$store.dispatch("cart/loadCart");

                setTimeout(() => {
                    this.$store.commit('app/setLoading', false);
                }, 3000);
            },
            handleClearAll() {
                this.$store.dispatch("cart/clearCart");
                this.$store.commit("product/clearAllCartProduct");
            },
            handleRemove(product) {
                this.$store.dispatch("cart/removeProductCart", product.id);
                this.$store.commit("product/removeCartProduct", product.id);
            },
            productPrice(price, taxIncluded) {
                if (taxIncluded) {
                    return price;
                } else {
                    return (price / 1.18).toFixed(2);
                }
            },
            productQuantity(product) {
                const item = this.cartItems.find(item => item.id === product.id);
                if (item) {
                    return item.quantity;
                }
                return 1;
            },
            productTotal(product, count) {
                let total = this.productPrice(product.price, this.taxIncluded ) * count;
                return total.toFixed(2);
            },
            handleDecreaseItem(product, count) {
                const params = {
                    quantity: count - 1,
                    id: product.id
                };
                this.$store.dispatch('cart/changeQuantityProductCart', params);
            },
            handleIncreaseItem(product, count) {
                const params = {
                    quantity: count + 1,
                    id: product.id
                };
                this.$store.dispatch('cart/changeQuantityProductCart', params);
                this.$store.commit('product/changeCartItemQuantity', params);
            },
            handleChangeQuantity(val, product) {
                if (val.data) {
                    const value = parseInt(val.data);
                    let params = {
                        quantity: value < 0 ? 0 : value,
                        id: product.id
                    };

                    this.$store.dispatch('cart/changeQuantityProductCart', params);
                    this.$store.commit('product/changeCartItemQuantity', params);
                }
            },
            createOrder() {

                if (this.user !== null) {
                    this.$store.commit('app/setLoading', true);
                    createOrder(this.token, '').then((data) => {
                        if (data.code === 200) {
                            this.$router.push(`/profile/order/${data.order}`);
                        } else {
                            alert(data.message + ": " + data.error);
                        }
                    })
                    this.$store.commit('app/setLoading', false);
                } else {
                    localStorage.setItem("goBackAfterLogin","/cart");
                    this.$router.push('/login');
                }

            }
        }
    }
</script>
