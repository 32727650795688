<template>
    <header :class="['ps-header ps-header--1 ', { 'ps-header--sticky': sticky }]">
        <div class="ps-noti bg-danger" v-if="showNotification">
            <div class="container">
                <p class="m-0 text-white">
                    <strong>300 &#8380;</strong>-dən yuxarı olan sifarişlər Bakı şəhəri üçün <strong>ÖDƏNİŞSİZ</strong> çatdırılır.
                </p>
            </div>
            <a href="#" class="ps-noti__close" @click="hideNotification"><i class="icon-cross"></i></a>
        </div>
        <div class="ps-header__middle">
            <div class="container">
                <div class="ps-logo">
                    <a href="/">
                        <img :src="logo" alt="Logo">
                        <img class="sticky-logo" :src="logo" alt="Logo">
                    </a>
                </div>
                <a class="ps-menu--sticky" href="#" @click.prevent="handleOpenMenu()"><i class="fa fa-bars"></i></a>
                <div class="ps-header__right">
                    <ul class="ps-header__icons">
                        <li class="">
                            <a href="#" class="active  ps-header__item open-search"
                               @click.prevent="handleOpenDrawer('search')"><i class="icon-magnifier"></i></a>
                        </li>
                        <li class="ps-header__user">
                            <router-link to="/profile" class="active  ps-header__item">
                                <i class="icon-user"></i>
                            </router-link>
                            <login-modal v-if="user==null"/>
                        </li>
                        <li class="">
                            <router-link to="/profile/favorites" class="ps-header__item"><i class="fa fa-heart-o"></i>
                                <span class="badge">{{wishlistItems.length }}</span></router-link>
                        </li>
                        <li class="ps-header__cart">
                            <a href="/cart" class="active  ps-header__item"><i class="icon-cart-empty"></i>
                                <span class="badge">{{ productCartQuantity }}</span>
                            </a>
                            <CartMini/>
                        </li>
                    </ul>
                    <div class="ps-language-currency" v-if="languages.length>1">
                        <a class="ps-dropdown-value" href="#" @click.prevent="handleOpenLanguageDialog()">
                            {{ language }}</a>
                    </div>
                    <FormSearch></FormSearch>
                </div>
            </div>
        </div>
        <div :class="['ps-navigation', { 'active': showNavigation }]">
            <div class="container">
                <div class="ps-navigation__left">

                    <nav class="ps-main-menu">
                        <MenuRenderer :list="mainMenu" :promos="promos" className="menu"/>
                    </nav>
                </div>
                <div class="ps-navigation__right">
                    <label>
                        <input type="radio" name="taxIncluded" value="1" v-model="isTaxIncluded">
                        ədv daxil
                    </label>
                    <label class="ml-2">
                        <input type="radio" name="taxIncluded" value="0" v-model="isTaxIncluded">
                        ədvsiz
                    </label>
                </div>
            </div>
        </div>

    </header>
    <div v-if="sticky" style="height: 200px;">

    </div>
</template>

<script>

    import MenuRenderer from "./MenuRenderer";
    import LoginModal from "../modals/LoginModal";
    import {mapState} from "vuex";
    import CartMini from "../modals/CartMini";
    import logo from "../../assets/img/logo.png";
    import {getBlocks, getCategories} from "../../repositories/GeneralDataRepository";
    import FormSearch from "./FormSearch";

    export default {
        name: "TheHeader",
        components: {
            FormSearch,
            CartMini,
            LoginModal, MenuRenderer,
        },
        data() {
            return {
                logo: logo,
                categories: [],
                promos: [],
                product: null,
                sticky: false,
                showNavigation: true,
                showNotification: true
            }
        },
        computed: {
            ...mapState({
                cartItems: state => state.cart.cartItems,
                wishlistItems: state => state.wishlist.items,
                appDrawer: state => state.app.appDrawer,
                language: state => state.app.language,
                languageCode: state => state.app.languageCode,
                languages: state => state.app.languages,
                user: state => state.auth.user,
                taxIncluded: state => state.app.taxIncluded
            }),
            isTaxIncluded: {
                get() {
                    return this.taxIncluded ? "1" : "0";
                },
                set(v) {
                    this.$store.commit('app/setTaxIncluded', v > 0);
                    localStorage.setItem('taxIncluded', v);
                }
            },
            mainMenu() {
                return [
                    /*{
                        "text": "Məhsullar",
                        "url": "/products",
                        "icon": "fa fa-bars",
                        "extraClass": "has-mega-menu",
                        "megaContent": [
                            {
                                "heading": "Kateqoriyalar",
                                "megaColumn": "col-12 col-sm-5",
                                "megaItems": this.categories,
                            },
                            {
                                "megaColumn": "col-12 col-sm-5 col-md-7",
                                "promo": true
                            }
                        ]
                    },*/
                    {
                        "icon": "fa fa-th-large",
                        "text": "Məhsullar",
                        "url": "/products",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-building",
                        "text": "Haqqımızda",
                        "url": "/page/about",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-lock",
                        "text": "Məxfilik",
                        "url": "/page/privacy",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-certificate",
                        "text": "Sertifikatlar",
                        "url": "/page/certificates",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-copyright",
                        "text": "Brendlər",
                        "url": "/brands",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-book",
                        "text": "Kataloqlar",
                        "url": "/catalogs",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-book",
                        "text": "Qalereya",
                        "url": "/gallery",
                        "extraClass": "nav-item mr-2"
                    },
                    {
                        "icons": "fa fa-envelope",
                        "text": "Əlaqə",
                        "url": "/contact",
                        "extraClass": "nav-item mr-2"
                    }
                ]
            },
            productCartQuantity() {
                let quantity = 0;
                this.cartItems.forEach(element => {
                    quantity += element.count;
                });
                return quantity;
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
            this.handleScroll();
        }
        ,
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll);
        }
        ,
        async mounted() {
            await getBlocks(this.languageCode, 'menu', true).then(data => {
                this.promos = data;
            });
            await getCategories(this.languageCode).then(data => {

                const categories = [];

                data.forEach(cat => {
                    categories.push({
                        text: cat.name,
                        url: `/category/${cat.slug}`
                    })
                })

                this.categories = categories;
            });
        }
        ,
        methods: {
            handleScroll() {
                let scroll = window.scrollY;
                if (scroll > 140 + (this.showNotification ? 36 : 0)) {
                    this.sticky = true;
                } else {
                    this.sticky = false;
                }
            }
            ,
            handleOpenDrawer(drawer) {
                this.$store.commit('app/setCurrentDrawerContent', drawer);
                this.$store.commit('app/setAppDrawer', !this.appDrawer);
            }
            ,
            handleOpenMenu() {
                this.showNavigation = !this.showNavigation;
            }
            ,
            handleOpenLanguageDialog() {
                this.$store.commit('app/setDialogLanguage', true);
            },
            hideNotification() {
                this.showNotification = false;
            }
        },

    }
</script>

<style scoped>

</style>