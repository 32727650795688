<template>
    <div style="padding-top: 50px;">
        <div class="ps-about" v-if="!isLoading">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
            </div>
            <div class="ps-about__content">
                <div class="container">
                    <h1 class="ps-blog__title">Brendlər</h1>
                </div>
                <div class="container">

                    <div class="row">

                        <div class="col-6 col-md-3 col-lg-2 mb-5" v-for="b in brandList" :key="b.id">
                            <img :src="`${baseUrl}/photo.php?w=400&h=200&zc=2&src=${b.photo}`" alt=""
                                 class="img-thumbnail img-fluid p-4">
                            <div class="font-weight-bold mt-2">
                                {{b.name}}
                            </div>
                        </div>

                    </div>


                </div>


            </div>
        </div>
        <div v-else>
            <div class="container">Loading...</div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import {getBrands} from "../../repositories/GeneralDataRepository";
    import {mapState} from "vuex";
    import {baseUrl} from "../../repositories/Repository";

    export default {
        name: "Brands",
        components: {Breadcrumb},
        data() {

            return {
                baseUrl: baseUrl,
                isLoading: true,
                breadcrumb: [],
                brandList: []
            };

        },
        computed: {
            ...mapState({
                languageCode: state => state.app.languageCode
            })
        },
        watch: {
            '$route'() {
                this.loadData();
            }
        },
        methods: {
            async loadData() {

                this.isLoading = true;
                this.$store.commit('app/setLoading', true);
                await getBrands(this.languageCode).then(data => {

                    this.brandList = data;
                    this.breadcrumb = [
                        {
                            url: '/',
                            text: 'Home'
                        },
                        {
                            url: 'brands',
                            extraClass: 'active',
                            text: "Brendlər"
                        }
                    ];
                    this.isLoading = false;
                })

                this.$store.commit('app/setLoading', false);

            }
        },

        async mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>