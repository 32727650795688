<template>
  <div class="ps-product__info">
    <div class="ps-product__badge">
            <span class="ps-badge ps-badge--instock" v-if="product.is_stock && productPrice>0">
                Stokda
            </span>
      <span class="ps-badge ps-badge--instock" v-else-if="productPrice===0">
              <router-link to="/contact">Get Quote</router-link>
            </span>
      <span class="ps-badge ps-badge--outstock" v-else>
                Tükənib
            </span>
    </div>
    <div class="ps-product__branch"><a>{{ product.category.name }}</a></div>
    <div class="ps-product__title">
      <a>{{ product.name }}</a>
    </div>

    <div class="ps-product__rating">
      <div class="d-inline-block">
        <i v-for="i in 5" :key="i" class="fa mr-1 text-warning"
           :class="{'fa-star': product.rating>i-1, 'fa-star-o': product.rating<i}"></i>
        {{ parseFloat(product.rating).toFixed(1) }}
      </div>

      <span class="ps-product__review">( {{ product.rating_count }} rəy)</span>
    </div>

    <div class="ps-product__desc" v-if="product.features !==''">
      <ul class="ps-product__list">
        <li class="d-block" v-for="f in product.features.split('\n')" :key="f">{{ f }}</li>
      </ul>
    </div>

    <div class="ps-product__type">
      <ul class="ps-product__list">
        <li><span class="ps-list__title">SKU: </span><a class="ps-list__text">{{ product.sku }}</a></li>
      </ul>
    </div>


    <div class="ps-product__meta" v-if="product.price >0">
      <div>
                <span :class="[ 'ps-product__price', product.sale_price ? 'sale' : '' ]">
                    {{ product.sale_price ? product.sale_price : productPrice }} AZN <span class="small text-secondary"
                                                                                           v-if="!taxIncluded">(ədv xaric)</span>
                </span>
        <span class="ps-product__del" v-if="product.sale_price"> {{ productPrice }} AZN</span>
      </div>
    </div>

    <div class="ps-product__quantity" v-if="product.is_stock && product.price>0">
      <h6> Miqdarı</h6>
      <div class="d-md-flex align-items-center">
        <div class="def-number-input number-input safari_only">
          <button class="minus" @click="quantity > 0 ? quantity-- : quantity"><i class="icon-minus"></i>
          </button>
          <input style="border: none" class="quantity" min="0" v-model="quantity" type="number"/>
          <button class="plus" @click="quantity++"><i class="icon-plus"></i></button>
        </div>
        <a class="ps-btn ps-btn--warning" href="#" @click.prevent="addToCart()">Səbətə əlavə et</a></div>
    </div>
    <div class="ps-product__quantity" v-if="product.is_stock && product.price===0">
      <router-link target="_blank" class="ps-btn ps-btn--warning" to="/contact">Get Quote</router-link>
    </div>
    <div class="ps-product__variations">
      <a class="ps-product__link" href="#" @click.prevent="removeProductFromWishlist()" v-if="isWishlist">Seçilmişlərdən
        sil</a>
      <a class="ps-product__link" href="#" @click.prevent="addProductToWishlist()" v-else>Seçilmişlərə əlavə
        et</a>
    </div>

    <p class="ps-desc" v-html="productDescription"></p>


    <div class="ps-product__social" v-if="design && design.social">
      <social-icon-color/>
    </div>
  </div>
</template>
<script>     import {baseUrl} from "../../repositories/Repository";
import SocialIconColor from "../layout/SocialIconColor";
import {mapState} from 'vuex';

export default {
  props: {
    product: {type: Object}, design: {
      type: Object, default: () => {
      }
    }
  }, components: {SocialIconColor}, data() {
    return {baseDomain: baseUrl, quantity: 1, selectFeature: []};
  }, computed: {
    ...mapState({
      wishlist: state => state.wishlist.items,
      taxIncluded: state => state.app.taxIncluded
    }),
    isWishlist() {
      const item = this.wishlist.find(item => parseInt(item) === this.product.id);
      if (item) {
        return true;
      }
      return false;
    }, rating() {
      return Math.floor(Math.random() * Math.floor(6) + 3);
    },
    productDescription() {
      if (this.product.description == null) {
        return '';
      } else {
        return this.nl2br(this.product.description, false);
      }
    },
    productPrice() {
      if (this.product.price <= 0) {
        return 0;
      }
      if (this.taxIncluded) {
        return this.product.price.toFixed(2);
      } else {
        return (this.product.price / 1.18).toFixed(2);
      }
    }
  }, methods: {
    nl2br(str, is_xhtml) {
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    handleSelectFeature(text, value) {
      if (this.selectFeature.length) {
        let check = false;
        this.selectFeature.forEach(element => {
          if (element.text == text) {
            element.value = value;
            check = true;
          }
        });
        if (!check) {
          const data = {text: text, value: value};
          this.selectFeature.push(data);
        }
      } else {
        const data = {text: text, value: value};
        this.selectFeature.push(data);
      }
    }, checkActive(text, value) {
      if (this.selectFeature.length) {
        let check = false;
        this.selectFeature.forEach(element => {
          if (element.text == text && element.value == value) {
            check = true;
          }
        });
        return check;
      }
      return false;
    }, addProductToWishlist() {
      const params = {id: this.product.id};
      const title = this.product ? this.product.name : '';
      const message = {icon: 'icon-shield-check', title: title, message: title + ' seçilmişlərə əlavə edildi!'};
      this.$store.dispatch('wishlist/addItemToWishlist', params);
      this.$store.commit('product/addWishlistItem', this.product);
      this.$store.commit('cart/setDialogAddCart2', false);
      this.$store.commit('app/setMessageNotify', message);
      this.$store.commit('app/setDialogNotify', true);
    }, removeProductFromWishlist() {
      const params = {id: this.product.id};
      this.$store.dispatch('wishlist/removeItemFromWishlist', params);
    }, addToCart() {
      const params = {
        productId: this.product.id,
        optionId: null,
        count: this.quantity
      };
      this.$store.commit('cart/setDialogQuickview', false);
      this.$store.dispatch('cart/addProductToCart', params);
      this.$store.commit('cart/setDialogAddCart', true);
      this.$store.commit('cart/setDialogAddCart2', false);
      this.$store.commit('cart/setProductOverview', this.product);
      this.$store.commit('product/addCartProduct', this.product);

    }
  }
}; </script>