<template>
    <ul class="ps-social ps-social--color">
        <li><a class="ps-social__link facebook" href="#"><i class="fa fa-facebook"> </i><span class="ps-tooltip">Facebook</span></a>
        </li>
        <li><a class="ps-social__link twitter" href="#"><i class="fa fa-twitter"></i><span
                class="ps-tooltip">Twitter</span></a></li>
        <li><a class="ps-social__link pinterest" href="#"><i class="fa fa-pinterest-p"></i><span class="ps-tooltip">Pinterest</span></a>
        </li>
        <li class="ps-social__linkedin"><a class="ps-social__link linkedin" href="#"><i class="fa fa-linkedin"></i><span
                class="ps-tooltip">Linkedin</span></a></li>
        <li class="ps-social__reddit"><a class="ps-social__link reddit-alien" href="#"><i
                class="fa fa-reddit-alien"></i><span class="ps-tooltip">Reddit Alien</span></a></li>
        <li class="ps-social__email"><a class="ps-social__link envelope" href="#"><i class="fa fa-envelope-o"></i><span
                class="ps-tooltip">Email</span></a></li>
        <li class="ps-social__whatsapp"><a class="ps-social__link whatsapp" href="#"><i class="fa fa-whatsapp"></i><span
                class="ps-tooltip">WhatsApp</span></a></li>
    </ul>
</template>

<script>
    export default {};
</script>