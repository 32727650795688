<template>
    <div>

        <div class="ps-shopping">
            <h3 class="ps-shopping__title">Sifarişlər </h3>

            <div class="my-4">

                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Qiymət</th>
                        <th>Status</th>
                        <th colspan="2">-</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-if="orders.length<1">
                        <td colspan="4">
                            <div class="alert alert-info">
                                Sizin sifarişiniz yoxdur
                            </div>
                        </td>
                    </tr>
                    <tr v-for="o in orders" :key="o.id">
                        <td>
                            <router-link :to="`/profile/order/${o.id}`">#{{o.id}}</router-link>
                        </td>
                        <td>
                            {{o.price}} AZN
                        </td>
                        <td>
                            <div class="badge bg-info text-white" v-if="o.orderStatus==='new'">
                                Yeni sifariş
                            </div>
                            <div class="badge bg-success text-white" v-else-if="o.orderStatus==='confirmed'">
                                Təsdiqlənmiş sifariş
                            </div>
                            <div class="badge bg-warning"
                                 v-else-if="o.orderStatus==='waiting_payment'">
                                Ödəniş tələb olunur
                            </div>
                            <div class="badge bg-success text-white" v-else-if="o.orderStatus==='paid'">
                                Ödəniş qəbul edilib
                            </div>
                            <div class="badge bg-success text-white" v-else-if="o.orderStatus==='preparing'">
                                Paketiniz hazırlanır
                            </div>
                            <div class="badge bg-success text-white" v-else-if="o.orderStatus==='delivering'">
                                Paketiniz yoldadır
                            </div>
                            <div class="badge bg-success text-white" v-else-if="o.orderStatus==='finished'">
                                Sifarişiniz tamalandı
                            </div>
                            <div class="badge bg-dark text-white" v-else-if="o.orderStatus==='cancelled'">
                                Ləğv edilib
                            </div>
                            <div class="abadge bg-success text-white" v-else>
                                {{o.orderStatus}}
                            </div>
                        </td>
                        <td>
                            <router-link class="text-primary" :to="`/profile/order/${o.id}`">ətraflı</router-link>
                        </td>

                        <td>
                            <a v-if="['draft','new','waiting_payment'].indexOf(o.orderStatus)>-1" href="#" @click="deleteOrder(o.id)"
                               class="text-danger">sifarişi sil</a>
                        </td>
                    </tr>

                    </tbody>
                </table>

            </div>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import {deleteOrder, getOrders} from "../../repositories/UserRepository";

    export default {
        layout: 'default',
        data() {
            return {
                baseDomain: baseUrl,
                orders: [],
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                token: state => state.auth.token,
            }),
        },
        methods: {
            deleteOrder(id) {
                if (confirm('Sifarişi silmək istədiyinizdən əminsiniz?')) {
                    deleteOrder(this.token, id).then(res => {
                        if (res) {
                            alert("Sifariş silindi");
                            this.loadOrderList();
                        } else {
                            alert('Sifariş silinə bilmir');
                        }
                    });
                }
            },

            loadOrderList(){
                getOrders(this.token).then((orders) => {
                    this.orders = orders;
                })
                this.$store.commit('app/setLoading', false);

            }
        },
        async created() {

            this.loadOrderList();

        },
    }
</script>
