<template>
    <div class="ps-product ps-product--standard" v-if="product">
        <div class="ps-product__thumbnail">
            <router-link class="ps-product__image" :to="`/product/${product.slug}`" v-if="product.thumbnail">
                <figure>
                    <img :src="imagePath + product.thumbnail.url" :alt="product.name"/>
                    <img v-if="product.thumbnail_back" :src="imagePath + product.thumbnail_back.url"
                         :alt="product.name"/>
                </figure>
            </router-link>
            <div class="ps-product__actions">
                <div class="ps-product__item" data-toggle="tooltip" data-placement="left" title="Seçilmişlər">
                    <a href="#" @click.prevent="removeProductFromWishlist()" v-if="product.isFavorite">
                        <i class="fa fa-heart"></i>
                    </a>
                    <a href="#" @click.prevent="addProductToWishlist()" v-else><i class="fa fa-heart-o"></i></a>
                </div>
                <div class="ps-product__item" data-toggle="tooltip" data-placement="left" title="Sürətli baxış">
                    <a href="#" @click.prevent="productQuickview()"><i class="fa fa-search"></i></a>
                </div>
                <div v-if="product.is_stock &&  product.price>0" class="ps-product__item" data-toggle="tooltip" data-placement="left"
                     title="Səbətə əlavə et">
                    <a href="#" @click.prevent="addToCart()"><i class="fa fa-shopping-basket"></i></a>
                </div>
            </div>
            <div class="ps-product__badge" v-if="product.badges.length">
                <div
                        v-for="item in product.badges"
                        :key="item.id"
                        :class="[
                        'ps-badge',
                        item.value === 'hot'
                            ? 'ps-badge--hot'
                            : item.value === 'new'
                            ? 'ps-badge--new'
                            : item.value === 'sale'
                            ? 'ps-badge--sale'
                            : 'ps-badge--sold'
                    ]"
                >
                    {{ item.name }}
                </div>
            </div>
            <div class="ps-product__percent" v-if="product.percent">
                {{ product.percent }}
            </div>
        </div>
        <div class="ps-product__content">
            <router-link class="ps-product__branch" :to="`/category/${product.category.slug}`">
                {{product.category.name}}
            </router-link>
            <h5 class="ps-product__title">
                <router-link :to="`/product/${product.slug}`">{{product.name}}</router-link>
            </h5>
            <div class="ps-product__meta" v-if=" product.price>0">
                <span :class="[ 'ps-product__price', product.sale_price ? 'sale' : '' ]">
                    {{ product.sale_price ? product.sale_price : productPrice }} AZN
                </span>
                <span class="ps-product__del" v-if="product.sale_price">
                    {{ productPrice }} AZN
                </span>
            </div>
            <div class="ps-product__meta" v-else-if=" product.is_stock">
                 <div class="badge bg-success text-white">
                   Get Quote
                 </div>
            </div>

            <div class="ps-product__rating">

                <div class="d-inline-block">
                    <i v-for="i in 5" :key="i" class="fa mr-1 text-warning"
                       :class="{'fa-star': product.rating>i-1, 'fa-star-o': product.rating<=i}"></i>
                    {{parseFloat(product.rating).toFixed(1)}}
                </div>

                <span class="ps-product__review">( {{product.rating_count}} Reviews)</span>
            </div>
            <!--
            <div class="ps-product__footer" v-if="product.footer">
                No of pcs <br/>available <span>{{ product.available }}</span>
            </div>-->
            <div class="ps-product__desc" v-if="product.features !==''">
                <ul class="ps-product__list">
                    <li v-for="f in featureList" :key="f">{{f}}</li>
                </ul>
            </div>
            <div class="ps-product__actions ps-product__group-mobile">
                <div class="ps-product__quantity">
                    <div class="def-number-input number-input safari_only">
                        <button
                                class="minus"
                                @click="quantity > 0 ? quantity-- : quantity"
                        >
                            <i class="icon-minus"></i>
                        </button>
                        <input
                                class="quantity"
                                min="0"
                                v-model="quantity"
                                type="number"
                                style="border: none"
                        />
                        <button class="plus" @click="quantity++">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="ps-product__cart" v-if=" product.is_stock &&  product.price>0">
                    <a class="ps-btn ps-btn--warning"
                       href="#"
                       @click.prevent="addToCart()">Səbətə əlavə et</a>
                </div>
                <div class="ps-product__item cart" v-if=" product.is_stock &&  product.price>0">
                    <a href="#" @click.prevent="addToCart()"
                    ><i class="fa fa-shopping-basket"></i
                    ></a>
                </div>
                <div class="ps-product__item">
                    <a href="#" @click.prevent="removeProductFromWishlist()" v-if="product.isFavorite">
                        <i class="fa fa-heart"></i>
                    </a>
                    <a href="#" @click.prevent="addProductToWishlist()" v-else>
                        <i class="fa fa-heart-o"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {baseUrl, imagePath} from "../../repositories/Repository";
    import {mapState} from "vuex";

    export default {
        props: {
            product: {
                type: Object
            }
        },
        components: {},
        data() {
            return {
                baseDomain: baseUrl,
                imagePath: imagePath,
                quantity: 1
            };
        },
        computed: {
            ...mapState({
                taxIncluded: state => state.app.taxIncluded
            }),
            rating() {
                return Math.floor(Math.random() * Math.floor(6) + 3);
            },
            productPrice() {
                if (this.product.price <= 0) {
                    return 0;
                }
                if (this.taxIncluded) {
                    return this.product.price.toFixed(2);
                } else {
                    return (this.product.price / 1.18).toFixed(2);
                }
            },
            featureList(){
                let list=  this.product.features.split('\n');
                if(list.length<4){
                    return list;
                } else {
                    return  list.slice(0,3);
                }
            }
        },
        methods: {
            addToCart() {
                const params = {
                    productId: this.product.id,
                    optionId: null,
                    count: this.quantity
                };
                this.$store.dispatch('cart/addProductToCart', params);
                this.$store.commit('cart/setDialogAddCart', true);
                this.$store.commit('cart/setProductOverview', this.product);
                this.$store.commit('cart/setDialogQuickview', false);
                this.$store.commit('product/addCartProduct', this.product);
                this.$store.commit('cart/setDialogAddCart2', false);
            },
            productQuickview() {
                this.$store.commit('cart/setProductOverview', this.product);
                this.$store.commit('cart/setDialogQuickview', true);
                this.$store.commit('cart/setDialogAddCart', false);
                this.$store.commit('cart/setDialogAddCart2', false);
            },
            addProductToWishlist() {
                const params = {
                    id: this.product.id
                };
                const title = this.product ? this.product.name : '';
                const message = {
                    icon: 'icon-star',
                    message: title + ' seçilmişlərə əlavə edildi!'
                };
                this.$store.dispatch('wishlist/addItemToWishlist', params);
                this.$store.commit('cart/setDialogAddCart2', false);
                this.$store.commit('app/setMessageNotify', message);
                this.$store.commit('app/setDialogNotify', true);
            },
            removeProductFromWishlist() {
                const params = {
                    id: this.product.id
                };
                this.$store.dispatch('wishlist/removeItemFromWishlist', params);
            }
        }
    };
</script>
