<template>
    <div>
        <div class="ps-shopping">
            <div class="container" v-if="orderDetails!== null">
                <h3 class="ps-shopping__title">Sifariş #{{orderDetails.id}} </h3>
                <div class="ps-shopping__content">
                    <div class="row">
                        <div class="col-12  col-lg-9">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">

                                        <div class="card-header">Çatdırılma</div>
                                        <div class="card-body" v-if="editShippingType">
                                            <form @submit.prevent="updateShippingInfo()">
                                                <div class="mb-3">
                                                    <label>Çatdırılma: </label>
                                                    <select class="form-control" v-model="currentShippingType">
                                                        <option value="take">Mağazadan götürəcəm</option>
                                                        <option value="delivery">Çatdırılma</option>
                                                    </select>
                                                </div>

                                                <div class="mb-3" v-if="currentShippingType==='take'">
                                                    <label>Mağazanı seçin</label>
                                                    <select class="form-control" v-model="currentBranch">
                                                        <option v-for="b in branches" :value="b.id" :key="b.id">
                                                            {{b.name}}
                                                        </option>
                                                    </select>

                                                    <div class="my-3" v-if="currentBranch >0">
                                                        <button type="submit" class="ps-btn ps-btn--warning">
                                                            Çatdırılma ayarlarını saxla
                                                        </button>
                                                        <div class="text-center mt-2"
                                                             v-if="orderDetails.shippingType !== 'none'">
                                                            <a class="ps-shopping__link"
                                                               @click.prevent="editShippingType=false">
                                                                ləğv et
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="mb-3" v-else-if="currentShippingType==='delivery'">
                                                    <label>Çatdırılma ünvanını seçin</label>

                                                    <div class="input-group">

                                                        <select class="form-control" v-model="currentAddress">
                                                            <option v-for="a in addresses" :value="a.id" :key="a.id">
                                                                {{a.name}}
                                                            </option>
                                                        </select>
                                                        <div class="input-group-append">
                                                            <a class="btn btn-lg btn-outline-primary p-4"
                                                               @click.prevent="addAddressOpen=!addAddressOpen">
                                                                <i class="fa fa-plus" v-if="!addAddressOpen"></i>
                                                                <i class="fa fa-minus" v-if="addAddressOpen"></i>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div class="my-3" v-if="addAddressOpen">
                                                        <hr>
                                                        <add-addess-form
                                                                @addressCreated="addressCreated"></add-addess-form>
                                                    </div>

                                                    <div class="my-3" v-if="selectedAddress">

                                                        <div>
                                                            Çatdırılma müddətini seçin
                                                        </div>

                                                        <label class="mr-3 d-block" v-for="p in enabledDeliveryOptions"
                                                               :key="p.id">

                                                            <input type="radio" name="deliveryOption"
                                                                   v-model="currentDeliveryOption" :value="p.id"
                                                                   required>
                                                            {{p.name}}
                                                            <span class="badge bg-danger text-white"
                                                                  v-if="orderDetails.totalPrice > p.free">Ödənişsiz </span>
                                                            <span v-else>({{p.price.toFixed(2)}} AZN)</span>
                                                        </label>

                                                        <div class="alert alert-warning mt-4" v-if="deliveryOptionNote">
                                                            {{deliveryOptionNote}}
                                                        </div>
                                                        <div class="alert alert-warning">
                                                            Dəqiq çatdırılma saatını dəqiqləşdirmək üçün kuryer sizinlə
                                                            əlaqə saxlayacaq
                                                        </div>

                                                    </div>
                                                    <div class="my-3" v-if="currentAddress>0">

                                                        <button type="submit" class="ps-btn ps-btn--warning">
                                                            Çatdırılma ayarlarını saxla
                                                        </button>
                                                    </div>
                                                    <div class="text-center mt-2"
                                                         v-if="orderDetails.shippingType !== 'none'">
                                                        <a class="ps-shopping__link"
                                                           @click.prevent="editShippingType=false">
                                                            ləğv et
                                                        </a>
                                                    </div>

                                                </div>

                                                <div v-else>
                                                    <div class="alert alert-info">
                                                        Zəhmət olmasa çatdırılma növünü seçin
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        <div class="card-body" v-else>
                                            <div class="mb-3">
                                                <strong v-if="orderDetails.shippingType==='take'">Mağazadan
                                                    götürəcəm</strong>
                                                <strong v-else-if="orderDetails.shippingType==='delivery'">Ünvana
                                                    çatdırılma</strong>
                                            </div>

                                            <div class="mb-3" v-if="orderDetails.shippingType==='take'">
                                                <div>
                                                    <strong>Mağazanın ünvanı:</strong> <br>
                                                    <span>{{orderDetails.branch.address}}</span> <br>
                                                    <strong>Mağazanın iş qrafiki</strong> <br>
                                                    <span v-html="orderDetails.branch.schedule"></span>
                                                </div>
                                            </div>
                                            <div class="mb-3" v-else-if="orderDetails.shippingType==='delivery'">
                                                <strong>{{orderDetails.address.name}}</strong> <br>
                                                {{orderDetails.address.district.city.name}},
                                                {{orderDetails.address.district.name}} <br>
                                                <em><span>{{orderDetails.address.address}}</span></em> <br> <br>
                                                <span><strong>{{orderDetails.deliveryOption.deliveryOption.name}}</strong> müddətində çatdırılacaq</span>
                                                <br>

                                            </div>

                                            <div v-else>
                                                <div class="alert alert-info">
                                                    Zəhmət olmasa çatdırılma növünü seçin
                                                </div>
                                            </div>

                                            <div class="my-3"
                                                 v-if="['new','confirmed','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                                                <a class="ps-shopping__link"
                                                   @click.prevent="editShippingType=true">
                                                    Çatdırılma ayarlarını dəyiş
                                                </a>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                                <div class="col-md-6">

                                    <div class="card mb-3" v-if="orderDetails.shippingType!=='none'">

                                        <div class="card-header">Ödəniş</div>
                                        <div class="card-body" v-if="editPaymentType">
                                            <form @submit.prevent="updatePaymentInfo()">
                                                <div class="mb-3">
                                                    <label>Ödəniş növü: </label>
                                                    <select class="form-control" v-model="currentPaymentType">
                                                        <option value="cash" v-if="orderDetails.shippingType ==='take'">
                                                            Çatdırılma zamanı nağd
                                                        </option>
                                                        <option value="card">Online</option>
                                                    </select>
                                                </div>


                                                <div class="my-3" v-if="currentPaymentType !== 'none'">
                                                    <button type="submit" class="ps-btn ps-btn--warning">
                                                        Ödəmə üsulunu təsdiqlə
                                                    </button>

                                                </div>
                                                <div class="text-center mt-2"
                                                     v-if="orderDetails.paymentType !== 'none'">
                                                    <a class="ps-shopping__link" href="#"
                                                       @click.prevent="editPaymentType=false">
                                                        ləğv et
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="card-body" v-else>
                                            <div class="mb-3">
                                                <strong>Ödəniş növü: </strong>
                                                <span v-if="orderDetails.paymentType==='cash'">Çatdırılma zamanı nağd</span>
                                                <span v-else-if="orderDetails.paymentType==='card'">Online</span>
                                                <span v-else>Zəhmət olmasa seçin</span>
                                            </div>

                                            <div class="my-3"
                                                 v-if="['new','confirmed','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                                                <a class="ps-shopping__link" href="#"
                                                   @click.prevent="editPaymentType=true">
                                                    Ödəniş ayarlarını dəyiş
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card mb-3" v-if="orderDetails.paymentType!=='none'">

                                        <div class="card-header">Sifarişlə bağlı qeyd</div>
                                        <div class="card-body" v-if="editOrderNote">
                                            <form @submit.prevent="updateOrderNote()">
                                                <div class="mb-3">
                                                    <textarea class="form-control" v-model="currentOrderNote" placeholder="Sifarişlə bağlı qeyd">

                                                    </textarea>
                                                </div>


                                                <div class="my-3">
                                                    <button type="submit" class="ps-btn ps-btn--warning">
                                                       Qeydi yadda saxla
                                                    </button>

                                                </div>
                                                <div class="text-center mt-2" >
                                                    <a class="ps-shopping__link" href="#"
                                                       @click.prevent="editOrderNote=false">
                                                        ləğv et
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="card-body" v-else>
                                            <div class="mb-3">
                                                 <span v-if="orderDetails.orderNotes">{{orderDetails.orderNotes}}</span>
                                                <i v-else class="text-secondary">qeyd yazılmayıb</i>
                                            </div>

                                            <div class="my-3"
                                                 v-if="['new','confirmed','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                                                <a class="ps-shopping__link" href="#"
                                                   @click.prevent="editOrderNote=true">
                                                    qeyd yaz
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="card mb-4">

                                <div class="card-header">Məhsullar</div>
                                <div class="card-body">

                                    <table class="table ps-table ps-table--product">
                                        <thead>
                                        <tr>
                                            <th class="ps-product__thumbnail"></th>
                                            <th class="ps-product__name">Məhsul</th>
                                            <th class="ps-product__meta">Qiymət</th>
                                            <th class="ps-product__quantity">Miqdar</th>
                                            <th class="ps-product__subtotal">Cəmi</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(orderItem, index) in orderItems" :key="index">
                                            <td class="ps-product__thumbnail">
                                                <router-link class="ps-product__image"
                                                             :to="`/product/${orderItem.product.slug}`"
                                                             v-if="orderItem.product.thumbnail">
                                                    <figure><img :src="baseDomain + orderItem.product.thumbnail.url"
                                                                 alt>
                                                    </figure>
                                                </router-link>
                                            </td>
                                            <td class="ps-product__name">
                                                <router-link :to="`/product/${orderItem.product.slug}`">
                                                    {{orderItem.product.name }}
                                                </router-link>
                                                <br>
                                                <a class="mx-3 btn btn-sm btn-warning"
                                                   @click="openRateModal(orderItem.product)"
                                                   v-if="orderDetails.orderStatus==='finished'">
                                                    dəyərləndir
                                                </a>

                                            </td>
                                            <td class="ps-product__meta">
                                            <span :class="[ 'ps-product__price',orderItem.product.sale_price ? 'sale' : '']">
                                                {{
                                                    orderItem.product.sale_price ? orderItem.product.sale_price : orderItem.product.price
                                                }} AZN
                                            </span>
                                                <span class="ps-product__del" v-if="orderItem.product.sale_price">
                                            {{ orderItem.product.price }} AZN
                                        </span>
                                            </td>
                                            <td class="ps-product__meta text-center">
                                                <span>{{ orderItem.count }} </span>
                                            </td>
                                            <td class="ps-product__meta">
                                                <span>{{ orderItem.price }} AZN </span>
                                            </td>
                                        </tr>
                                        <tr v-if="!orderItems || !orderItems.length">
                                            <td colspan="6">Yüklənir...</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-lg-3">

                            <div>

                                <div class="alert alert-info" v-if="orderDetails.orderStatus==='new'">
                                    Yeni sifariş
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='confirmed'">
                                    Təsdiqlənmiş sifariş
                                </div>
                                <div class="alert alert-warning"
                                     v-else-if="orderDetails.orderStatus==='waiting_payment'">
                                    Online ödəniş tələb olunur
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='paid'">
                                    Online ödəniş qəbul edilib. Sifarişiniz işlənilir
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='preparing'">
                                    Paketiniz hazırlanır
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='delivering'">
                                    Paketiniz yoldadır
                                </div>
                                <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='finished'">
                                    Sifarişiniz tamalandı
                                </div>
                                <div class="alert alert-danger" v-else-if="orderDetails.orderStatus==='cancelled'">
                                    Ləğv edilib
                                </div>
                                <div class="alert alert-info" v-else>
                                    {{orderDetails.orderStatus}}
                                </div>

                            </div>

                            <div class="ps-shopping__box">
                                <div class="ps-shopping__row">
                                    <div class="ps-shopping__label">Cəmi</div>
                                    <div class="ps-shopping__price">{{ orderDetails.totalPrice }} AZN</div>
                                </div>
                                <div class="ps-shopping__row" v-if="orderDetails.personalDiscountPercent>0">
                                    <div class="ps-shopping__label">Şəxsi endirim</div>
                                    <div class="ps-shopping__price">{{ orderDetails.personalDiscountAmount }} AZN
                                        ({{orderDetails.personalDiscountPercent}} %)
                                    </div>
                                </div>
                                <div class="ps-shopping__row">
                                    <div class="ps-shopping__label">Çatdırılma</div>
                                    <div class="ps-shopping__price">{{ orderDetails.deliveryPrice }} AZN</div>
                                </div>
                                <div class="ps-shopping__row">
                                    <div class="ps-shopping__label">Ödəniləcək</div>
                                    <div class="ps-shopping__price">{{ orderDetails.price }} AZN</div>
                                </div>
                                <div class="ps-shopping__checkout">
                                    <a href="#" class="ps-btn ps-btn--warning" @click.prevent="payOrder()"
                                       v-if="orderDetails.paymentType==='card' && orderDetails.orderStatus === 'waiting_payment'">
                                        Ödənişə davam et
                                    </a>
                                    <router-link class="ps-shopping__link" to="/products">
                                        Alışverişə davam et
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-else>
                <div class="alert alert-info">
                    Loading...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import {
        getAddressList,
        getOrder,
        payOrder,
        setOrderShippingInfo,
        setPaymentInfo, updateOrderNote
    } from "../../repositories/UserRepository";
    import {getBranches} from "../../repositories/GeneralDataRepository";

    import AddAddessForm from "../layout/AddAddessForm";

    export default {
        layout: 'default',
        components: {AddAddessForm},
        data() {
            return {

                baseDomain: baseUrl,
                orderDetails: null,
                orderItems: [],
                currentShippingType: '',
                currentAddress: 0,
                currentBranch: 0,
                currentDeliveryOption: 0,
                editShippingType: false,
                branches: [],
                addresses: [],
                currentPaymentType: '',
                editPaymentType: false,
                addAddressOpen: false,
                editOrderNote: false,
                currentOrderNote:''
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                token: state => state.auth.token,
            }),
            orderId() {
                return this.$route.params.id;
            },
            breadcrumb() {
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/profile/orders',
                        text: 'Sifarişlər'
                    },
                    {
                        url: '/profile/order/' + this.orderDetails.id,
                        extraClass: 'active',
                        text: 'Sifariş #' + this.orderDetails.id
                    }
                ];
            },
            selectedAddress() {

                if (this.currentAddress > 0) {

                    let selected = this.addresses.filter(a => a.id === this.currentAddress);
                    if (selected.length > 0) {
                        return selected[0];
                    }
                }

                return null;

            },
            enabledDeliveryOptions() {
                let list = [];
                if (this.selectedAddress) {
                    this.selectedAddress.pricing.forEach(p => {
                        if (this.orderDetails.totalPrice >= p['min'] && this.orderDetails.totalPrice <= p['max']) {
                            list.push(p);
                        }
                    })
                }
                return list;
            },
            deliveryOptionNote() {
                let note = '';
                if (this.currentDeliveryOption) {
                    this.enabledDeliveryOptions.forEach(p => {
                        if (this.currentDeliveryOption == p['id']) {
                            note = p['note'];
                        }
                    })
                }
                return note;
            },
            deliveryBranch: {
                get() {
                    if (this.orderDetails.branch == null) {
                        return null
                    } else {
                        return this.orderDetails.branch.id;
                    }
                },
                set(id) {
                    this.orderDetails.branch = {id: id};
                }
            },
            deliveryAddress: {
                get() {
                    if (this.orderDetails.address == null) {
                        return null
                    } else {
                        return this.orderDetails.address.id;
                    }
                },
                set(id) {
                    this.orderDetails.address = {id: id};
                }
            },
        },
        async created() {

            this.loadOrder();

            this.loadAddressList();

            getBranches('az').then((branches) => {
                this.branches = branches;
            })


            this.$store.commit('app/setLoading', false);
        },
        methods: {
            loadOrder() {
                getOrder(this.token, this.orderId).then((data) => {
                    if (data !== null) {
                        this.orderDetails = data.order;
                        this.orderItems = data.items;
                        this.currentOrderNote = this.orderDetails.orderNotes;
                        this.currentShippingType = this.orderDetails.shippingType;
                        if (this.orderDetails.shippingType === 'none') {
                            this.editShippingType = true;
                        }
                        if (this.orderDetails.address == null) {
                            this.currentAddress = 0;
                        } else {
                            this.currentAddress = this.orderDetails.address.id;
                            this.currentDeliveryOption = this.orderDetails.deliveryOption.id;
                        }

                        if (this.orderDetails.branch == null) {
                            this.currentBranch = 0;
                        } else {
                            this.currentBranch = this.orderDetails.branch.id;
                        }

                        this.currentPaymentType = this.orderDetails.paymentType;
                        if (this.orderDetails.paymentType === 'none') {
                            this.editPaymentType = true;
                        }
                    } else {
                        this.orderDetails = null;
                        this.orderItems = [];
                    }
                })
            },
            handleCoupon() {
                console.log('couponCode', this.couponCode);
            },
            updateShippingInfo() {

                setOrderShippingInfo(
                    this.token, this.orderDetails.id, this.currentShippingType,
                    this.currentBranch, this.currentAddress, this.currentDeliveryOption, ''
                ).then((res) => {
                    if (res) {
                        this.editShippingType = false;
                        this.loadOrder();
                    } else {
                        alert('Çatdırılma ünvanı dəyişdirilə bilmir!');
                    }
                });

            },
            updatePaymentInfo() {

                setPaymentInfo(
                    this.token, this.orderDetails.id, this.currentPaymentType
                ).then((res) => {
                    if (!res) {
                        alert('Ödəniş forması dəyişdirilə bilmir!');
                    } else {
                        if (res.code === 200) {
                            this.editPaymentType = false;
                            this.loadOrder();
                        } else {
                            alert(`${res.messages}: ${res.error}`);
                        }
                    }
                });

            },
            updateOrderNote() {

                updateOrderNote(
                    this.token, this.orderDetails.id, this.currentOrderNote
                ).then((res) => {
                    if (!res) {
                        alert('Qeyd dəyişdirilə bilmir!');
                    } else {
                        if (res.code === 200) {
                            this.editOrderNote = false;
                            this.loadOrder();
                        } else {
                            alert(`${res.messages}: ${res.error}`);
                        }
                    }
                });

            },
            payOrder() {

                payOrder(
                    this.token, this.orderDetails.id
                ).then((data) => {
                    if (data === null) {
                        alert('Əməliyyatı icra etmək mümkün olmadı, zəhmət olmasa bir az sonra təkrar cəhd edin')
                    } else {
                        window.location.href = data;
                    }
                });

            },

            openRateModal(product) {
                this.$store.commit('cart/setDialogRating', true);
                this.$store.commit('cart/setProductOverview', product);
            },
            loadAddressList() {
                getAddressList(this.token).then((addresses) => {
                    this.addresses = addresses;
                })
            },
            addressCreated(addressId) {
                this.loadAddressList();
                this.deliveryAddress = addressId;
                this.addAddressOpen = false;
            }
        }
    }
</script>
