<template lang="html">
    <div class="ps-preloader" id="preloader" v-show="loading">
        <div class="ps-preloader-section ps-preloader-left"></div>
        <div class="ps-preloader-section ps-preloader-right"></div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: 'Loading',
    computed: {
        ...mapState({
            loading: state => state.app.loading
        })
    },
};
</script>

<style lang="scss" scoped></style>
