<template>

    <div v-if="pageNotFound">
        <NotFoundPage></NotFoundPage>
    </div>
    <div v-else>
        <div class="ps-category">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>

                <h1 class="ps-category__name">{{pageTitle}}<sup v-if="totalProductSize>0">({{totalProductSize}})</sup>
                </h1>

                <div class="ps-category__content">
                    <div class="row row-reverse">
                        <div class="col-12 col-md-9">


                            <shop-wrapper
                                    :type="type"
                                    :pageSize="pageSize"
                                    :sort="sortBy"
                                    :filter="onlyStock"
                                    @handleChangeType="handleChangeType"
                                    @handleChangeSort="handleChangeSort"
                                    @handleChangePageSize="handleChangePageSize"
                                    @handleChangeStock="handleChangeStock"
                            />
                            <div v-if="type === 'list'" class="ps-category--list">
                                <template v-if="loading">
                                    <div class="ps-product ps-product--list" v-for="item in 3" :key="item">
                                        <!--<div class="ps-product__content">
                                            <div class="ps-product__thumbnail">
                                                <v-skeleton-loader type="image" ></v-skeleton-loader>
                                            </div>
                                            <div class="ps-product__info">
                                                <v-skeleton-loader type="article" ></v-skeleton-loader>
                                            </div>
                                        </div>
                                        <div class="ps-product__footer">
                                            <v-skeleton-loader type="article" ></v-skeleton-loader>
                                        </div>-->
                                    </div>
                                </template>
                                <template v-else>
                                    <product-listing v-for="item in productList" :key="item.id" :product="item"/>
                                </template>
                            </div>
                            <div v-else-if="type === 'grid2'" class="ps-category--grid ps-category--detail">
                                <div class="row" v-if="loading">
                                    <div class="col-6 col-md-4" v-for="item in 3" :key="item">
                                        <!--<v-skeleton-loader type="image, article" ></v-skeleton-loader>-->
                                    </div>
                                </div>
                                <div class="row m-0" v-else>
                                    <div
                                            class="col-6 col-md-4 p-0"
                                            v-for="item in productList"
                                            :key="item.id"
                                    >
                                        <product-standard :product="item"/>
                                    </div>
                                </div>
                            </div>
                            <div class="ps-category--grid" v-else-if="type === 'grid3'">
                                <div class="row" v-if="loading">
                                    <div class="col-6 col-md-4 col-lg-3" v-for="item in 4" :key="item">
                                        <!--<v-skeleton-loader type="image, article" ></v-skeleton-loader>-->
                                    </div>
                                </div>
                                <div class="row m-0" v-else>
                                    <div
                                            class="col-6 col-md-4 col-lg-3 p-0"
                                            v-for="item in productList"
                                            :key="item.id"
                                    >
                                        <product-standard :product="item"/>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="ps-category--separate">
                                <div class="row" v-if="loading">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="item in 4" :key="item">
                                        <!--<v-skeleton-loader  type="image, article" ></v-skeleton-loader>-->
                                    </div>
                                </div>
                                <div class="row m-0" v-else>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-0" v-for="item in productList"
                                         :key="item.id">
                                        <product-standard :product="item"/>
                                    </div>
                                </div>
                            </div>
                            <pagination v-if="pageLength>0"
                                        :page="pageIndex"
                                        :length="pageLength"
                                        @handleChangePage="handleChangePage"
                            />
                            <!--<delivery />-->


                            <div class="row" v-if="pageBanner!==''" style="margin-top: 100px;">
                                <div class="col-md-6">
                                    <img :src="`${mediaBase}/photo.php?w=500&h=250&zc=1&src=${pageBanner}`"
                                         class="w-100 mb-3">
                                </div>
                                <div class="col-md-6">
                                    <div>{{pageDescription}}</div>
                                </div>
                            </div>


                        </div>
                        <div class="col-12 col-md-3">

                            <div class="ps-widget ps-widget--product">
                                <div class="ps-widget__block">
                                    <h4 class="ps-widget__title">Kateqoriyalar</h4>

                                    <div class="ps-widget__content ps-widget__category">
                                        <ul class="menu--mobile">
                                            <li v-for="item in categories"
                                                :class="[item.extraClass, {'active': activeCategory === item.id}]"
                                                :key="item.id"
                                            >
                                                <router-link class="font-weight-normal" :to="`/category/${item.slug}`">
                                                    {{ item.name }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import ProductStandard from "../product/ProductStandard";
    import ShopWrapper from "../layout/ShopWrapper";
    import ProductListing from "../product/ProductListing";
    import Pagination from "../layout/Pagination";
    import {mapState} from "vuex";
    import {getCategories} from "../../repositories/GeneralDataRepository";
    import {getAllProducts, getCategory} from "../../repositories/ProductRepository";
    import NotFoundPage from "./NotFoundPage";
    import {baseUrl} from "../../repositories/Repository";

    export default {
        name: "Products",
        components: {NotFoundPage, ProductStandard, ShopWrapper, Breadcrumb, ProductListing, Pagination},
        props: {
            pageType: {
                type: String,
                default: () => {
                }
            },
            categorySlug: {
                type: String,
                default: () => {
                }
            },
        },
        data() {
            return {
                mediaBase: baseUrl,
                type: 'grid3',
                products: [],
                totalProductSize: 0,
                pageSize: 24,
                pageIndex: 1,
                onlyStock: false,
                sortBy: 'recommended',
                categories: [],
                activeCategory: 1,
                pageTitle: "",
                pageBanner: "",
                pageDescription: "",
                pageNotFound: false
            };
        },
        watch: {
            '$route'() {
                //this.getProductList();
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            pageSize(newVal, oldVal){
                console.log(oldVal + ' -> ' + newVal);
            }
        },
        computed: {
            ...mapState({
                languageCode: state => state.app.languageCode,
                loading: state => state.app.loading,
                favorites: state => state.wishlist.items
            }),
            slug() {
                return this.$route.params.slug;
            },
            pageLength() {
                return (this.totalProductSize > 0 ? Math.ceil(this.totalProductSize / this.pageSize) : 0);
            },
            productList() {
                let result = [];
                this.products.forEach((p) => {
                    p.isFavorite = this.favorites.indexOf(p.id + "") > -1;
                    result.push(p)
                });
                return result;
            },
            breadcrumb() {
                let list = [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/products',
                        text: 'Məhsullar'
                    }
                ];

                if (this.pageType === 'category') {
                    list.push(
                        {
                            url: '/category/' + this.slug,
                            extraClass: 'active',
                            text: this.pageTitle
                        }
                    )
                }
                return list;
            },
        },
        mounted() {
            this.$store.commit('app/setLoading', true);
            getCategories(this.languageCode).then((data) => {
                this.categories = data;
            })

            console.log(this.$route.query);

            if (this.$route.query.page) {
                this.pageIndex = this.$route.query.page;
            }
            if (this.$route.query.size) {
                this.pageSize = this.$route.query.size;
            }
            if (this.$route.query.sort) {
                this.sortBy = this.$route.query.sort;
            }
            if (this.$route.query.view) {
                this.type = this.$route.query.view;
            }

            this.getProductList();
        },
        methods: {
            async getProductList() {

                switch (this.pageType) {
                    case "category":
                        await getCategory(this.languageCode, this.slug).then(cat => {
                            if (cat.length < 1) {
                                this.pageNotFound = true;
                            } else {
                                this.pageNotFound = false;
                                this.activeCategory = cat.id;
                                this.pageTitle = cat.name;
                                this.pageBanner = cat.banner;
                                this.pageDescription = cat.description;
                                return cat;
                            }
                        }).then(cat => {
                            this.$router.push(`/category/${cat.slug}?page=${this.pageIndex}&size=${this.pageSize}&sort=${this.sortBy}&view=${this.type}`);

                            getAllProducts(this.languageCode, "", cat.id, this.pageIndex, this.pageSize, this.sortBy, this.onlyStock).then(data => {
                                this.products = data.products;
                                this.totalProductSize = data.total
                                this.pageNotFound = false;
                            });
                        })

                        break;
                    default:
                        this.activeCategory = 0;
                        this.pageTitle = "Məhsullar";
                        this.pageBanner = "";
                        this.$router.push(`/products?page=${this.pageIndex}&size=${this.pageSize}&sort=${this.sortBy}&view=${this.type}`);

                        await getAllProducts(this.languageCode, "", 0, this.pageIndex, this.pageSize, this.sortBy, this.onlyStock).then(data => {
                                this.products = data.products;
                                this.totalProductSize = data.total
                            }
                        );
                }


                this.$store.commit('app/setLoading', false);
            },
            handleChangePage(val) {
                this.pageIndex = val;
                this.getProductList();
                window.scrollTo({
                    top: 0, left: 0,
                    behavior: 'smooth'
                });
            },
            handleChangeType(val) {
                this.type = val;
            },
            handleChangeSort(val) {
                this.sortBy = val;
                this.pageIndex = 1;
                this.getProductList();
            },
            handleChangePageSize(val) {
                this.pageSize = val;
                this.pageIndex = 1;
                this.getProductList();
            },
            handleChangeStock(val) {
                this.pageIndex = 1;
                this.onlyStock = val;

                console.log(val);
                this.getProductList();
            },
            handleFilter(type, value) {
                // list type: price, color, branch, rating
                console.log(type, value);
            }
        }
    }
</script>

<style scoped>

</style>