<template>
    <div class="container" style="margin-top: 60px;">
        <section :class="['ps-section--banner', sectionClass]">
            <div class="ps-section__overlay" v-show="loadingBanner">
                <div class="ps-section__loading"></div>
            </div>
            <div class="ps-swiper">
                <div class="swiper" style="overflow: unset !important;">
                    <swiper :slides-per-view="1" navigation :pagination="{ clickable: true }"
                            :autoplay="{delay: 5000,}"  effect="cube" :initailSlide="0" :speed="1000"
                            :space-between="0" :loop="true">
                        <swiper-slide v-for="(item, index) in listData" :key="index">
                            <div class="ps-banner--round" style="border: 1px solid #ccc;" v-if="round">
                                <banner :banner="item"/>
                            </div>
                            <banner :banner="item" v-else/>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Banner from "./Banner";
    import SwiperCore, {Navigation, Pagination, Autoplay, EffectCube, A11y} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    import 'swiper/swiper.scss';
    import 'swiper/components/navigation/navigation.scss';
    import 'swiper/components/pagination/pagination.scss';
    import 'swiper/components/scrollbar/scrollbar.scss';
    // import 'swiper/components/effect-cube/effect-cube.scss';

    SwiperCore.use([Navigation, Pagination,Autoplay, EffectCube ,  A11y]);

    export default {
        components: {Banner, Swiper, SwiperSlide},
        props: {
            listData: {
                type: Array,
                default: () => []
            },
            sectionClass: {
                type: String,
                default: ''
            },
            round: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loadingBanner: true
            };
        },
        computed: {
            ...mapState({
                loading: state => state.app.loading
            })
        },
        mounted() {
            setTimeout(() => {
                this.loadingBanner = false;
            }, 1000);
        }
    };
</script>

<style lang="scss">

    @import "../../assets/style/style";
    @import "https://unpkg.com/swiper@7/swiper-bundle.min.css";
    @import "https://unpkg.com/swiper@7/modules/effect-cube/effect-cube.min.css";

    .swiper-container-cube {
        overflow: visible;
        .swiper-slide {
            pointer-events: none;
            backface-visibility: hidden;
            z-index: 1;
            visibility: hidden;
            transform-origin: 0 0;
            width: 100%;
            height: 100%;
            .swiper-slide {
                pointer-events: none;
            }
        }
        &.swiper-container-rtl .swiper-slide {
            transform-origin: 100% 0;
        }
        .swiper-slide-active {
            &,
            & .swiper-slide-active {
                pointer-events: auto;
            }
        }
        .swiper-slide-active,
        .swiper-slide-next,
        .swiper-slide-prev,
        .swiper-slide-next + .swiper-slide {
            pointer-events: auto;
            visibility: visible;
        }
        .swiper-slide-shadow-top,
        .swiper-slide-shadow-bottom,
        .swiper-slide-shadow-left,
        .swiper-slide-shadow-right {
            z-index: 0;
            backface-visibility: hidden;
        }
        .swiper-cube-shadow {
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            z-index: 0;

            &:before {
                content: '';
                background: #000;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                -webkit-filter: blur(50px);
                filter: blur(50px);
            }
        }
    }


</style>
