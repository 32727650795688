<template>
    <div>


        <section-banner :listData="banners" :round="true"/>

        <div class="ps-home__content mt-5">
            <div class="ps-promo ps-not-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-4"
                             v-for="(item, index) in promotionTop"
                             :key="index">
                            <promo-item :item="item"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ps-promo ps-not-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-xs-6 col-sm-4 col-md-3 col-xl-2 mb-5"
                             v-for="(c, index) in categories"
                             :key="index">
                            <div class="card h-100 rounded-0 bg-danger" style="cursor: pointer"
                                 @click="$router.push('/category/'+c.slug)">
                                <img :src="`${basePath}/photo.php?w=400&h=400&zc=2&src=${c.icon}`"
                                     class="card-img-top">
                                <div class="card-body">


                                    <div class="card-text text-white font-weight-bold">
                                        {{c.name}}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <!--<section class="ps-section&#45;&#45;category ps-category&#45;&#45;image">
                <div class="container">
                    <div class="ps-section__content">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 mb-4"  v-for="(c, index) in categories"  :key="index">
                                <div class="ps-category__thumbnail">
                                    <a :href="`/category/${c.slug}`" class="ps-category__image">
                                        <img :src="`${basePath}/photo.php?w=150&h=150&zc=2&src=${c.icon}`" alt="">
                                    </a>
                                    <div class="ps-category__content">
                                        <a :href="`/category/${c.slug}`" class="ps-category__name">
                                            {{c.name}}
                                        </a>
&lt;!&ndash;                                        <a href="/shop" class="ps-category__more">More</a>&ndash;&gt;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>-->

            <!--            <info-block-list title="Niyə MSInshaat?" subtitle="Test text for substitle" block="home"/>-->

            <featured-product title="Ən çox satılan məhsullar" tag="top"/>

        </div>
    </div>
</template>

<script>
    import PromoItem from "../layout/PromoItem";
    import FeaturedProduct from "../layout/FeaturedProduct";
    import {getBlocks, getCategories} from "../../repositories/GeneralDataRepository";
    import {baseUrl, imagePath} from "../../repositories/Repository";
    import roundImg from "../../assets/img/round.png";
    import {mapState} from "vuex";
    import SectionBanner from "../layout/SectionBanner";

    export default {
        name: "HomePage",
        components: {PromoItem, FeaturedProduct, SectionBanner},
        data() {

            return {
                mediaPath: imagePath,
                basePath: baseUrl,
                banners: [],
                promotionTop: [],
                categories: []
            };

        },
        computed: {
            ...mapState({
                'languageCode': state => state.app.languageCode
            })
        },
        async mounted() {
            this.$store.dispatch('app/setLoading', true);

            await getBlocks(this.languageCode, 'sliders', false).then(data => {
                if (data.length < 1) {
                    this.$router.push("/");
                } else {
                    const banners = [];

                    data.forEach(function (item) {
                        banners.push({
                            "title": item.title,
                            "desc": item.text,
                            "shop": "bg-warning",
                            "img": baseUrl + item.photo,
                            "link": item.link,
                            "round": roundImg,
                            "bgColor": "#fcfcfe",
                            "bannerRound": false,
                            "filter": "filter: invert(9%) sepia(19%) saturate(9%) hue-rotate(352deg) brightness(101%) contrast(91%);"
                        })
                    })

                    this.banners = banners;
                }
            })
            await getCategories(this.languageCode).then(data => {
                if (data.length < 1) {
                    this.$router.push("/");
                } else {

                    this.categories = data;
                }
            })

            await getBlocks(this.languageCode, 'banners', true).then(data => {
                if (data.length < 1) {
                    this.$router.push("/");
                } else {
                    this.promotionTop = data;
                }
            })

            this.$store.dispatch('app/setLoading', false);

        }
    }
</script>

<style scoped>

</style>